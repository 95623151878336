import React, { useEffect, useState } from 'react';
import { Message, Image, Dropdown, Button, Popup, Grid } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { AuthState, Department, User } from '../../types';
import { fetchAllActiveServicesTypes, getServiceCategories } from '../../services/service';
import { fetchDepartments } from '../../services/Departments';
import { Service } from '../../types/Service';
import { mealTypes, sendToast } from '../../util';
import { withRouter, RouteComponentProps } from 'react-router';
import AssignButton from '../AssignButton';
import { convertStandaloneRequestToServiceRequest } from '../../services/RequestInstances';
import { RequestInstance } from '../../types/RequestInstance';
import { reopenStandaloneRequest } from '../../services/Requests';
import { fetchAllServiceInstance } from '../../services/ServiceInstances';
import moment from 'moment';
import { capitalizeFirstLetter } from '../../pages/AdminPanel/Menu/utils';
import { ServicesType } from '../../types/ServicesTypes';

interface StandaloneDetailedFormProps extends RouteComponentProps {
    request: RequestInstance;
    reqTypeIcon: string;
    avatarUrl: string;
    formatSvcRequestTime: (time: any) => string;
    users: User[];
    userLoading: boolean;
    onAssignSelection: (selection: any) => void;
    isAssignBtnDisabled: boolean;
    locationState: any;
    closeBtn: (loading: boolean) => React.ReactNode;
    renderFaces: (request: any) => React.ReactNode;
}

const StandaloneDetailedForm: React.FC<StandaloneDetailedFormProps> = ({ request, reqTypeIcon, avatarUrl, formatSvcRequestTime, users, userLoading, onAssignSelection, isAssignBtnDisabled, locationState, closeBtn, renderFaces, history }) => {
    const [departments, setDepartments] = useState<Department[]>([]);
    const [allServices, setAllServices] = useState<ServicesType[]>([]);
    const [filteredServices, setFilteredServices] = useState<ServicesType[]>([]);
    const [categories, setCategories] = useState<string[]>([]);
    const [selectedDepartment, setSelectedDepartment] = useState<string>('');
    const [selectedService, setSelectedService] = useState<string>('');
    const [selectedMealItems, setSelectedMealItems] = useState<string[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [filteredCategories, setFilteredCategories] = useState<string[]>([]);
    const [convertLoading, setConvertLoading] = useState<boolean>(false);
    const [reopenLoading, setReopenLoading] = useState<boolean>(false);
    const profile = useSelector(({ authReducer }: { authReducer: AuthState; }) => authReducer.profile);
    const [selectedMealType, setSelectedMealType] = useState<string>('');   
    const [isMenuItems, setIsMenuItems] = useState<boolean>(false);

    useEffect(() => {
        populateServiceDepartmentAndCategoryData();
    }, [profile]);

    useEffect(() => {
        if (selectedCategory && selectedDepartment) {
            handleFetchServices();
        }
    }, [selectedCategory, selectedDepartment]);

    async function handleFetchServices() {
        try {
            setLoading(true);
            const isCategoryMenu = selectedCategory === "Menu";
            const department = departments.find(dept => String(dept._id) === String(selectedDepartment));
            const isDepartmentDining = department && department.Name === "Dining";
            if (isCategoryMenu && isDepartmentDining && profile) {
                //fetch service instance items for the day and time 
                const menuFilter = {
                    Facility: profile.Facility,
                    source: "Menu" as "Menu",
                    startDate: moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss'), // Start of the day formatted
                    endDate: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss')
                };
                const menuItems = await fetchAllServiceInstance(menuFilter);
                setAllServices(menuItems);
                setFilteredServices(menuItems);
                setIsMenuItems(true);
            } else if (isMenuItems && profile) { // this is to avoid multiple calls to fetch services    
                const serviceFilter = { Facility: profile.Facility, active: true };
                const services = await fetchAllActiveServicesTypes(serviceFilter);
                const filteredServices = services.filter(service => service.Department === selectedDepartment || service.category === selectedCategory);
                // filter categories based on the services of the request
                setFilteredServices(filteredServices);
                setIsMenuItems(false);
            }
        } catch (error) {
            console.error("Error fetching services", error);
            sendToast('error', 'Something went wrong while fetching services !. Please try again later.');
        } finally {
            setLoading(false);
        }
    }

    const populateServiceDepartmentAndCategoryData = async () => {
        try {
            if (profile && profile.Facility) {
                setLoading(true);
                const serviceFilter = { Facility: profile.Facility, active: true };
                const cateogryFilter = { includeAllCategories: true };
                const [services, allDepartments, categories] = await Promise.all([fetchAllActiveServicesTypes(serviceFilter), fetchDepartments(), getServiceCategories({ Filter: cateogryFilter, sortAlphabetically: true })]);
                setAllServices(services);
                setDepartments(allDepartments);
                setCategories(categories);
                setSelectedDepartment(request.Department);
                //filter services based on the department of the request
                const filteredServices = services.filter(service => service.Department === request.Department);
                // filter categories based on the services of the request
                setFilteredServices(filteredServices);
                // filter categories based on the services of the request
                const serviceCategories = filteredServices.map(service => service.category);
                setFilteredCategories(categories.filter(category => serviceCategories.includes(category)));
            }
        } catch (error) {
            console.error("Error fetching departments and services", error);
            sendToast('error', 'Something went wrong while fetching details !. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const handleDropDownChange = (type: string, value: any) => {
        try {
            if (type === 'Department') {
                setSelectedDepartment(value);
                setSelectedService('');
                setSelectedCategory('');
                //filter services based on the department
                const filteredServices = allServices.filter(service => service.Department === value);
                setFilteredServices(filteredServices);
                // filter categories based on the services of the request
                const serviceCategories = filteredServices.map(service => service.category);
                setFilteredCategories(categories.filter(category => serviceCategories.includes(category)));
                setIsMenuItems(false);
            } else if (type === 'Service') {
                if (isMenuItems) {
                    setSelectedMealItems(value);
                } else {
                    setSelectedService(value);
                }
                // get the selected service and set the category
                const selectedService = allServices.find(service => service._id === value);
                if (selectedService && selectedService.category) {
                    setSelectedCategory(selectedService.category);
                }
            } else if (type === 'Category') {
                // if category is selected then filter the services from the filtered service based on the category
                setSelectedCategory(value);
                if (value) {
                    const filteredCategoriesService = allServices.filter(service => service.Department === selectedDepartment && service.category === value);
                    setFilteredServices(filteredCategoriesService);
                } else {
                    const filteredCategoriesService = allServices.filter(service => service.Department === selectedDepartment);
                    setFilteredServices(filteredCategoriesService);
                    setIsMenuItems(false);
                }
            } else if (type === 'MealCategory') {
                setSelectedMealType(value);
                if (value) {
                    const filteredMealTypeService = allServices.filter(service => service.menuCategory === value);
                    setFilteredServices(filteredMealTypeService);
                } else {
                    setFilteredServices(allServices);
                }
            }
        } catch (error) {
            console.error("Error handling dropdown change", error);
            sendToast('error', 'Something went wrong while handling dropdown change !. Please refresh the page and try again.');
        }
    };

    const handleConvert = async () => {
        try {
            setConvertLoading(true);
            if (request && (selectedService || selectedMealItems.length > 0)) {
                const convertRequestData : {
                    standaloneRequestId: string,
                    serviceId?: string,
                    mealItems?: string[]
                } = {
                    standaloneRequestId: String(request._id),
                };

                if (selectedService) {
                    convertRequestData.serviceId = String(selectedService);
                }

                if (selectedMealItems.length > 0) {
                    convertRequestData.mealItems = selectedMealItems;
                }

                await convertStandaloneRequestToServiceRequest(convertRequestData);
                sendToast('success', 'Request converted successfully you will be redirected to requests page.');
                history.push('/admin/requests');

            } else {
                sendToast('warn', 'Please select a service to convert the request');
            }
        } catch (error) {
            console.error("Error converting request", error);
            sendToast('error', 'Something went wrong while converting request !. Please try again later.');
        } finally {
            setConvertLoading(false);
        }
    };

    const getDescriptionPart = (text: string) => {
        if (text.includes("Ride:ride") || text.includes("place:") || text.includes("time:") || text.includes("day:") || text.includes("Dietary")) {
            let formattedText = text
                .replace(/Ride:ride\s*/i, '') // Remove "Ride:ride"
                .replace(/place:(\w+)/i, (match, p1) => `Place: ${p1.charAt(0).toUpperCase() + p1.slice(1)}`) // Capitalize place name
                .replace(/time:(\d{2}):(\d{2})/i, (match, hour, minute) => {
                    const hours = parseInt(hour, 10);
                    const suffix = hours >= 12 ? "PM" : "AM";
                    const adjustedHour = hours % 12 || 12;
                    return `Time: ${adjustedHour}:${minute} ${suffix}`;
                })
                .replace(/day:(\d{2})-(\d{2})-(\d{4})/i, (match, month, day, year) => `Date: ${month}/${day}/${year}`)
                .replace(/Dietary:(\w+)/i, (match, p1) => `Dietary: ${p1.charAt(0).toUpperCase() + p1.slice(1)}`)
                .replace(/description:(\w+)/i, (match, p1) => `Description: ${p1.charAt(0).toUpperCase() + p1.slice(1)}`);
            formattedText = formattedText.replace(/^[,\s]+|[,\s]+$/g, '');
            return formattedText;
        }
        const descKeyword = "description:";
        const descIndex = text.indexOf(descKeyword);
        if (descIndex !== -1) {
            const descPart = text.slice(descIndex + descKeyword.length).trim();
            return "Description: " + descPart;
        }
        return text;
    };

    const handleRequestTest = (request, key: string) => {
        if (key === "Details") {
            if (request.Details) {
                const word = getDescriptionPart(request.Details);
                return word;
            }
        }
        if (key === "Name") {
            if (request.Name && request.Name.includes("Nursing-Assistance")) {
                return request.Name.replace("Nursing-", "");
            } else {
                return request.Name;
            }
        }
    };

    return (
        <>
            <div className="header">
                <div className="image-container icon-container">
                    <Image circular size="tiny" src={reqTypeIcon} />
                </div>
                <div className="short-details">
                    <div className="req-info-text">
                        {request.Registrant_FirstName + " " + request.Registrant_LastName}
                    </div>
                    <div className="req-info-text">
                        Room {request.Unit && request.Unit_Name}
                    </div>
                    <Grid style={{ margin: "10px" }}>
                        <Grid.Row verticalAlign="middle" style={{ margin: "0px" }}>
                            <Grid.Column width={5}>
                                <label>Department</label>
                            </Grid.Column>
                            <Grid.Column width={11}>
                                <Dropdown
                                    style={{ width: '100%' }}
                                    options={departments.map((dept) => ({
                                        key: dept._id,
                                        text: dept.Name,
                                        value: dept._id,
                                    }))}
                                    placeholder="Select Department"
                                    selection
                                    value={selectedDepartment}
                                    search={true}
                                    loading={loading}
                                    onChange={(e, { value }) => handleDropDownChange("Department", value)}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row verticalAlign="middle" style={{ margin: "0px" }}>
                            <Grid.Column width={5} >
                                <label>Category</label>
                            </Grid.Column>
                            <Grid.Column width={11}>
                                <Dropdown
                                    style={{ width: '100%' }}
                                    options={filteredCategories.length > 0 ? filteredCategories.map((category) => ({
                                        key: category,
                                        text: category,
                                        value: category,
                                    })) : [{ key: 'no-category', text: 'No Category Available', value: 'no-category', disabled: true }]}
                                    placeholder={filteredCategories.length > 0 ? "Select Category" : "No Category Available"}
                                    selection
                                    value={selectedCategory}
                                    search={true}
                                    clearable
                                    loading={loading}
                                    onChange={(e, { value }) => handleDropDownChange("Category", value)}
                                    onBlur={(e) => {
                                        // Prevent auto-select of the first item on blur
                                        if (!filteredCategories.find(category => category === selectedCategory)) {
                                            handleDropDownChange("Category", null);
                                        }
                                    }}
                                    disabled={filteredCategories.length === 0}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        {isMenuItems && <Grid.Row verticalAlign="middle" style={{ margin: "0px" }}>
                            <Grid.Column width={5} >
                                <label>Meal Type</label>
                            </Grid.Column>
                            <Grid.Column width={11}>
                                <Dropdown
                                    style={{ width: '100%' }}
                                    options={mealTypes.filter((mealType)=> mealType !== "Any").map((mealType, index) => ({
                                        key: index,
                                        text: capitalizeFirstLetter(mealType),
                                        value: mealType.toLocaleLowerCase(),
                                    }))}
                                    placeholder={"Select Meal Type"}
                                    selection
                                    clearable
                                    value={selectedMealType}
                                    search={true}
                                    loading={loading}
                                    onChange={(e, { value }) => handleDropDownChange("MealCategory", value)}
                                />
                            </Grid.Column>
                        </Grid.Row>}
                        <Grid.Row verticalAlign="middle" style={{ margin: "0px" }}>
                            <Grid.Column width={5} >
                                <label>{isMenuItems ? "Menu Items" : "Service"}</label>
                            </Grid.Column>
                            <Grid.Column width={11}>
                                <Dropdown
                                    style={{ width: '100%' }}
                                    options={filteredServices.length > 0 ? filteredServices.map((service) => ({
                                        key: service._id,
                                        text: service.name,
                                        value: service._id,
                                    })) : [{ key: 'no-service', text: 'No Service Available', value: 'no-service', disabled: true }]}
                                    placeholder={filteredServices.length > 0 ? "Select Service" : "No Service Available"}
                                    selection
                                    value={isMenuItems ? selectedMealItems : selectedService}
                                    search={true}
                                    loading={loading}
                                    multiple={isMenuItems}
                                    onChange={(e, { value }) => handleDropDownChange("Service", value)}
                                    disabled={filteredServices.length === 0}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <div className="req-info-text">{handleRequestTest(request, "Name")}</div>
                    {request.Description && (
                        <div className="req-info-text">
                            Description: {request.Description}
                        </div>
                    )}
                </div>
                <div className="image-container">
                    <Image circular size="tiny" src={avatarUrl} />
                </div>
            </div>
            {request.Details && <div className="req-info-text">{handleRequestTest(request, "Details")}</div>}
            {request.Registrant_Notes && (
                <div className="req-info-text">{request.Registrant_Notes}</div>
            )}
            <Message size="small" style={{ textAlign: "left" }}>
                <strong>Request Id: {request && request._id}</strong>
                <br />
                <br />
                <strong>
                    Created {formatSvcRequestTime(request && request.DateAdded)} by{" "}
                    {request &&
                        (
                            (request.Registrant_FirstName || "") +
                            " " +
                            (request.Registrant_LastName || "")
                        ).trim()}
                </strong>
                <br />
                {request && request.AcceptedTime && (
                    <strong>
                        <br />
                        Accepted by: {(request && request.AcceptedByName) || ""}{" "}
                        {formatSvcRequestTime(request && request.AcceptedTime)}
                        <br />
                    </strong>
                )}
                {request && request.ClosedTime && (
                    <strong>
                        <br />
                        Closed by: {(request && request.ClosedByName) || ""}{" "}
                        {formatSvcRequestTime(request && request.ClosedTime)}
                        <br />
                    </strong>
                )}
            </Message>
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                {
                    request.Department !== selectedDepartment ? <Popup
                        content="You can assign after conversion."
                        trigger={
                            <div>
                                <AssignButton
                                    users={users}
                                    loading={userLoading}
                                    onSelectionChange={onAssignSelection}
                                    disabled={true}
                                    locationState={locationState}
                                />
                            </div>
                        }
                    /> :
                        <div>
                            <AssignButton
                                users={users}
                                loading={userLoading}
                                onSelectionChange={onAssignSelection}
                                disabled={isAssignBtnDisabled || convertLoading} // disable assign button if request is not open, accepted, or if the request is being converted
                                locationState={locationState}
                            />
                        </div>
                }
                <div>
                    {closeBtn(convertLoading)}
                </div>
                {request.Status === 'Closed' ? <div>
                    <Button
                        loading={reopenLoading}
                        color='red'
                        basic
                        type='button'
                        onClick={async () => {
                            try {
                                setReopenLoading(true);
                                await reopenStandaloneRequest(request._id);
                            } catch (error) {
                                sendToast('error', error instanceof Error ? error.message : 'Something went wrong while reopening the request !. Please try again later.');
                            } finally {
                                setReopenLoading(false);
                                history.push('/admin/requests');
                            }
                        }}
                    >
                        <b>Reopen</b>
                    </Button>
                </div> : null}
            </div>
            {
                renderFaces(request)
            }
            <Button style={{ marginTop: "15px" }} disabled={!(selectedService || (selectedMealItems && selectedMealItems)) || !selectedDepartment} onClick={handleConvert} loading={convertLoading}>
                Convert
            </Button>
        </>
    );
};

export default withRouter(StandaloneDetailedForm);