import React, { FC, useEffect, useRef, useState } from 'react'
import { Button, Checkbox, Dimmer, Loader, Message, Modal } from 'semantic-ui-react'
import * as Promise from 'bluebird'
import moment from 'moment-timezone'
import { CSVLink } from 'react-csv'
import { AuthState, Registrant,  RegistrantRelative,  UserProfile } from '../../../../types'
import { fetchAllPaginatedResidents, fetchPaginatedRegistrantA4HContacts } from '../../../../services/Registrants'
import { connect, useSelector } from 'react-redux'
import { getSmartGadget, initGeneratingRegistrantDSNExport } from '../../../../services/SmartGadgets'
import { getOneQueueStatusByFilter } from '../../../../services/AlexaHospitalityQueueStatus'
import { sendToast } from '../../../../util'

interface Props {
    getAllSmartGadgets: () => Promise
    profile:UserProfile;
}

const ExportRegistrantButton: FC<Props> = (props) => {
    const [exportData, setExportData] = useState([])
    const [exportDataLoading, setExportDataLoading] = useState(false)
    const [exportOptionsModal, setExportOptionsModal] = useState<boolean>(false);
    const [latestExportQueueStatus, setLatestExportQueueStatus] = useState<any>(null);
    const [latestExportQueueStatusLoading, setLatestExportQueueStatusLoading] = useState<boolean>(false);
    const [latestDSNExport, setLatestDSNExport] = useState<any>(null);
    const [exportOptions, setExportOptions] = useState<{
        includeContacts: boolean;
        includeNonResidentialRoomOccupants: boolean;
        includeSmartGadgets: boolean;
    }>({
        includeContacts: false,
        includeNonResidentialRoomOccupants: false,
        includeSmartGadgets: false,
    })

    const excelRef = useRef()
    const Profile = useSelector(({ authReducer }: { authReducer: AuthState }) => {
        return authReducer.profile;
    })
    const facilityTimezone = Profile && Profile.Facility && Profile.FacilityTimeZone || "";

    useEffect(() => {
        if (exportData.length > 0) {
            // WIP see how you can avoid this issue instead of using ts-ignore
            // @ts-ignore
           excelRef && excelRef.current && excelRef.current.link && excelRef.current.link.click()
        }
    }, [exportData])

    const resyncDeviceInformation = async () => {
        setLatestExportQueueStatusLoading(true);
        try {
            await initGeneratingRegistrantDSNExport();
            sendToast('success', 'Export generation for device information initiated. Please check back after few minutes to download the latest export');
        } catch (error) {
            sendToast('error', error instanceof Error ? error.message : 'There was an issue with generating latest export, Please try again later');
        } finally {
            setLatestExportQueueStatusLoading(false);
            setExportOptionsModal(false);
        }
    }

    const exportClickHandler = async () => {
        setExportDataLoading(true);
        try {
            const latestQueueItem = await getOneQueueStatusByFilter({
                filters: {
                    EventType: 'ResidentExport',
                },
            });
            const queueItemStatus = latestQueueItem.Status;
            if (queueItemStatus === 'inProgress') {
                setLatestExportQueueStatusLoading(true);
                setLatestExportQueueStatus(latestQueueItem);
            } else if (queueItemStatus === 'success') {
                setLatestExportQueueStatusLoading(false);
                setLatestExportQueueStatus(latestQueueItem);
                const DSNFileURL = latestQueueItem.ExportFileUrl;
                const DSNFileExportObj = await fetch(DSNFileURL);
                if (!DSNFileExportObj.ok) {
                    throw new Error('Export file not found');
                }
                const residentDSNExport = await DSNFileExportObj.json(); // Wait for JSON to be parsed
                setLatestDSNExport(residentDSNExport);
            } else { // case failed/error object
                throw new Error('Export failed or Invalid');
            }
        } catch (error) {
            setLatestExportQueueStatusLoading(false);
            setLatestExportQueueStatus(null);
        }
        setExportOptionsModal(true);
        setExportDataLoading(false);
    }

    const handleExport = async (registrants) => {
        setExportDataLoading(true)
        // const gadgetsData = await props.getAllSmartGadgets()
        let arrObj = await Promise.map(registrants, async (e) => {
            let data: RegistrantRelative[] = []
            if (exportOptions.includeContacts) {
                data = await fetchPaginatedRegistrantA4HContacts(e._id);
            }
            
            return {
                'Resident Name': `${e.FirstName} ${e.LastName}`,
                'Resident Room': (e.Unit && e.Unit.Name) || 'NA',
                ...(exportOptions.includeSmartGadgets && {
                    'Alexa DSNs': (latestDSNExport && Array.isArray(latestDSNExport[String(e._id)]) && latestDSNExport[String(e._id)]) || 'NA',
                }),
                ...(exportOptions.includeContacts && {
                    Contacts:
                        data.length && data[0]
                            ? data
                                  .map((e) => {
                                      const str = `${e.RelativeName}: ${e.Contact}`;
                                      if (e.Contact) {
                                          return str;
                                      } else {
                                          return 'NA';
                                      }
                                  })
                                  .join('\n')
                            : 'NA',
                }),
            };
        }, {
            concurrency: 5
        })
        setExportDataLoading(false)
        setExportData(arrObj)
    }
    const exportReportHandler = async () => {
        setExportDataLoading(true)
        const res = await fetchAllPaginatedResidents({ IncludeNonResidentialRoomOccupants: exportOptions.includeNonResidentialRoomOccupants })
        handleExport(res)
    }

    return (
        <>
            <Dimmer active={exportDataLoading} inverted>
                <Loader active={exportDataLoading}>Please wait; this process may take some time depending on the number of residents in the facility. Kindly avoid refreshing the page while the export is in progress.</Loader>
            </Dimmer>
            <Button content={exportDataLoading ? 'Loading CSV...' : 'Export'} disabled={exportDataLoading} basic onClick={async() => await exportClickHandler()} />
            <CSVLink
                data={exportData}
                asyncOnClick={true}
                filename="Residents.csv"
                ref={excelRef}
                style={{
                    display: 'none',
                }}
            />
            <Modal
                open={exportOptionsModal}
                size='tiny'
                onClose={() => {
                    setExportOptionsModal(false);
                }}
            >
                <Modal.Header>Export Residents</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <p>Please select the options you want to include in the export</p>
                    </Modal.Description>
                    <div className="spacing">
                        <Checkbox
                            label='Include Contacts'
                            checked={exportOptions.includeContacts}
                            onChange={(e, { checked }) => {
                                setExportOptions({
                                    ...exportOptions,
                                    includeContacts: checked || false,
                                });
                            }}
                        />
                    </div>
                    <div className="lessSpacing">
                        <Checkbox
                            label='Include Hidden Residents'
                            checked={exportOptions.includeNonResidentialRoomOccupants}
                            onChange={(e, { checked }) => {
                                setExportOptions({
                                    ...exportOptions,
                                    includeNonResidentialRoomOccupants: checked || false,
                                });
                            }}
                        />
                    </div>
                    <div className="lessSpacing">
                        <Checkbox
                            label='Include Smart Device Information'
                            checked={exportOptions.includeSmartGadgets}
                            onChange={(e, { checked }) => {
                                setExportOptions({
                                    ...exportOptions,
                                    includeSmartGadgets: checked || false,
                                });
                            }}
                        />
                    </div>
                    {
                        (!latestExportQueueStatusLoading && exportOptions.includeSmartGadgets && latestExportQueueStatus && exportOptions.includeSmartGadgets) && (
                            <Message content={`Your last export was ${moment.tz((latestExportQueueStatus.LastUpdated || latestExportQueueStatus.DateAdded), facilityTimezone).format('MM/DD/YYYY hh:mm A')}. Click Export last to download this copy or select Create new for a new one.`}></Message>
                        )
                    }
                    {
                        (!latestExportQueueStatusLoading && exportOptions.includeSmartGadgets && (latestExportQueueStatus === null)) && (
                            <Message warn content={`Unable to fetch the latest export. Please try reloading the page. If this issue persists please try creating new  export for device information.`}></Message>
                        )
                    }
                    {
                        latestExportQueueStatusLoading && <Message info content={`We're preparing your export. Please come back in a few minutes.`}></Message>
                    }
                </Modal.Content>
                {!exportOptions.includeSmartGadgets ? (
                    <Modal.Actions>
                        <Button
                            content="Export"
                            onClick={async () => {
                                setExportOptionsModal(false);
                                await exportReportHandler();
                            }}
                        />
                        <Button
                            content="Cancel"
                            onClick={() => {
                                setExportOptionsModal(false);
                            }}
                        />
                    </Modal.Actions>
                ) : (
                    <Modal.Actions>
                        <Button
                            content="Create new"
                            disabled={latestExportQueueStatusLoading}
                            loading={latestExportQueueStatusLoading}
                            onClick={async () => {
                                await resyncDeviceInformation();
                            }}
                        />
                        <Button
                            content="Export last"
                            disabled={latestExportQueueStatusLoading || (exportOptions.includeSmartGadgets && (latestExportQueueStatus === null))}
                            loading={latestExportQueueStatusLoading}
                            onClick={async () => {
                                setExportOptionsModal(false);
                                await exportReportHandler();
                            }}
                        />
                        <Button
                            content="Cancel"
                            onClick={() => {
                                setExportOptionsModal(false);
                            }}
                        />
                    </Modal.Actions>
                )}
            </Modal>
        </>
    )
}

function mapStateToProps({ authReducer }) {
    return {
        currentUser: authReducer.currentUser,
        profile: authReducer.profile,
    }
}

export default connect(mapStateToProps)(ExportRegistrantButton)
