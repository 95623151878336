import { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setRemoteVideo } from '../actions/remoteVideo';
import { getTURNCreds } from '../services/WebRTC';
import { AuthState } from '../types';
import { sendToast } from '../util';

type Message = {
    sessionId?: string,
    type: string,
    sdp?: string,
    id?: string,
    target?: string,
    source?: string,
    [key: string]: any,
    callerId?: string,
    callerName?: string,
    message?: string
}

const useWebSocket = (url: string, proflieId: string, getLocalStream: () => MediaStream | null, postEndCall?: () => any) => {
    const [message, setMessage] = useState<Message | null>(null); // later the message can be used.
    const webSocket = useRef<WebSocket | null>(null);
    const [sessionId, setSessionId] = useState(`${Math.random() * 1000000}`)
    const peerConnectionRef = useRef<RTCPeerConnection | null>(null);
    const [isCallIncoming, setIsCallIncoming] = useState(false);
    const [isOutboundCallInitiated, setIsOutboundCallInitiated] = useState(false);
    const [isCallAccepted, setIsCallAccepted] = useState(false);
    const [isCallInProgress, setIsCallInProgress] = useState(false);
    const [connectionAttempts, setConnectionAttempts] = useState(0);
    const [isReconnecting, setIsReconnecting] = useState(false);
    const [isCallRinging, setIsCallRinging] = useState<boolean>(false);
    const [iceStateServers, setIceStateServers] = useState({ 'iceServers': [{ 'urls': 'stun:stun.l.google.com:19302' }] })
    const [reOfferInprogress, setReOfferInprogress] = useState(false);
    const dispatch = useDispatch();
    const profile = useSelector(({ authReducer }: { authReducer: AuthState }) => {
        return authReducer.profile;
    });
    const [caller, setCaller] = useState<{ callerId: string | null; callerName: string | null }>({
        callerId: null,
        callerName: null,
      });
    const handleWebSocketIssues = (message) => {
        if (connectionAttempts < 5) {
            setIsReconnecting(true);
            setTimeout(() => {
                webSocket.current = new WebSocket(url);
                setIsReconnecting(false);
            }, 2000);
            setConnectionAttempts(connectionAttempts + 1);
        } else {
            toast.error(message, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            setIsReconnecting(false);
        }
    };


    useEffect(() => {
        webSocket.current = new WebSocket(url);
        let pingIntervalId: NodeJS.Timeout | null = null;
        webSocket.current.onopen = () => {
            if (webSocket.current && webSocket.current.readyState === WebSocket.OPEN) {
                setIsReconnecting(false);
                sendMessage({
                    type: "register",
                    id: proflieId
                })

                // Set up a ping interval
                pingIntervalId = setInterval(() => {
                    if (webSocket.current && webSocket.current.readyState === WebSocket.OPEN) {
                        // Send ping every 5 seconds
                        sendMessage({ type: "ping" });
                    }
                }, 5000);
            }
        };
        const handleAnswer = (msg) => {
            if (peerConnectionRef.current) {
                peerConnectionRef.current.setRemoteDescription(new RTCSessionDescription(msg));
            }
            setIsCallInProgress(true)
            setIsOutboundCallInitiated(false)
            setIsCallAccepted(true);
            setIsCallRinging(false);
        }

        const handleOfferFromAlexa = (msg: Message) => {
            setIsCallIncoming(true);
            setCaller({
                callerId: msg.callerId || null,
                callerName: msg.callerName || null,
            });
            setSessionId(msg.sessionId || "")
            sendMessage({
                ...msg,
                type: "ringing",
            });
            setMessage(msg)
        }

        const handleRinging = (msg: Message) => {
            setIsCallRinging(true);          
        }

        const onMessage = (msg: Message) => {
            if (msg.type !== "pong") {
                console.log({ msg })
            }
            switch (msg.type) {
                case "offer":
                    handleOfferFromAlexa(msg)
                    break;
                case "answer":
                    handleAnswer(msg)
                    break;
                case "reoffer":
                    handleReOffer(msg);
                    break;
                case "reanswer":
                    break;
                case "end":
                    endCall(false /* isDeclined */, true /* alexaDeclined */);
                    break;
                case "ringing":
                    handleRinging(msg)
                    break;
                case "error":
                    if (msg.message && msg.message.includes("401")) {
                        sendToast("error", "Error occurred in the backend. Please refresh the site and try again.");
                        endCall();
                    } else {
                        sendToast("error", msg.message || "An error occurred, please refresh the site and call again.")
                    }
                    break;
                case "pong":
                    console.log('Received pong in response to ping');
                    break;
                default:
                    console.error("Unhandled event type: ", msg.type);
            }
        }

        webSocket.current.onmessage = (event) => {
            const parsedMessage = JSON.parse(event.data) as Message;
            if (parsedMessage.type !== "pong") { // avoid storing pong response
                setMessage(parsedMessage);
            }
            onMessage(parsedMessage);
        };

        webSocket.current.onerror = (error) => {
            console.error("WebSocket connection error: ", error);
            handleWebSocketIssues("WebSocket connection attempts maxed out");
        };

        webSocket.current.onclose = (event) => {
            console.error("WebSocket connection closed: ", event);
            handleWebSocketIssues("WebSocket connection attempts maxed out");
        };
        (async () => {
            try {
                const iceServers = await getTURNCreds(profile && profile._id || "");
                if (iceServers) {
                    setIceStateServers({
                        'iceServers': iceServers
                    });
                } else {
                    console.error("This facility is not enabled"); //since only featured facilty have webrtc feature no toast is shown
                }
            } catch (error) {
                console.error(error);
            }
        })();

        return () => {
            if (pingIntervalId) { // don't send ping in the next 5 sec if component is un mounted.
                clearInterval(pingIntervalId);
            }
            webSocket.current && webSocket.current.close();
        };
    }, [url, proflieId]);

    const sendMessage = (msg) => {
        if (webSocket.current && webSocket.current.readyState === WebSocket.OPEN) {
            const stringifiedMessage = JSON.stringify({
                sessionId: sessionId,
                ...msg
            });
            webSocket.current.send(stringifiedMessage);
        } else {
            console.error("WebSocket not ready to send messages");
        }
    };

    function setupCodecPreferences(peerConnection: RTCPeerConnection) {
        /*
          * This function is used to set up the codec preferences for an RTCPeerConnection so that streaming is fast on alexa devices.
          * It retrieves the transceivers of the peer connection and sets the H.264 codec as the preference.
          * This codec is commonly used in video systems for its balance of quality, compression, and support.
          * @param {RTCPeerConnection} peerConnection - The RTCPeerConnection whose codec preferences are to be set.
       */
        if (typeof peerConnection.getTransceivers !== 'function') return;

        let transceivers = peerConnection.getTransceivers();

        if (transceivers.length <= 1) return;

        let h264 = transceivers[1];
        let videoCapabilities = RTCRtpReceiver.getCapabilities('video');

        if (videoCapabilities === null) return;

        let h264_codecs = videoCapabilities.codecs.filter(codec => codec.mimeType === "video/H264");

        if (h264 && typeof h264.setCodecPreferences === 'function') {
            h264.setCodecPreferences(h264_codecs);
        }
    }

    const handleIncomingCallAccecpt = async () => {
        setIsCallAccepted(true);
        setIsCallIncoming(false);
        setCaller({ callerId: null, callerName: null });
        setIsCallInProgress(true);
        const peerConnection = new RTCPeerConnection(iceStateServers);
        peerConnectionRef.current = peerConnection;

        const localStream = getLocalStream();
        if (localStream && message) {
            peerConnection.ontrack = (event) => {
                dispatch(setRemoteVideo(event.streams[0]));
            };

            peerConnection.onicecandidateerror = (event) => {
                // Handle ICE candidate error event here
                console.log("onicecandidateerror", event)
            }

            peerConnection.oniceconnectionstatechange = function (event) {
                if (peerConnection.iceConnectionState === 'failed') {
                    // Handle ICE connection failed state here
                    console.log("oniceconnectionstatechange failed", event)
                }
            };

            peerConnection.onsignalingstatechange = function (event) {
                if (peerConnection.signalingState === 'closed') {
                    // Handle signaling state closed here
                    console.log("onsignalingstatechange closed", event)

                }
            }

            peerConnection.onicegatheringstatechange = (event: any) => {
                if (event && event.target && event.target.iceGatheringState === "complete" && event.target.localDescription) {
                    sendMessage({
                        type: event.target.localDescription.type,
                        sdp: event.target.localDescription.sdp,
                        source: proflieId
                    });
                }
            }

            // @ts-ignore
            await peerConnection.setRemoteDescription(message);
            localStream.getTracks().forEach(track => peerConnection.addTrack(track, localStream));

            const answer = await peerConnection.createAnswer();
            await peerConnection.setLocalDescription(answer);

            setupCodecPreferences(peerConnection);
        }
    };

    const refreshSessionId = () => {
        setSessionId(`${Math.random() * 1000000}`)
    }

    const startCall = async (unitId: string) => {
        setIsOutboundCallInitiated(true);
        peerConnectionRef.current = new RTCPeerConnection(iceStateServers);
    
        const localStream = getLocalStream();
        if (localStream) {
            const tracks = localStream.getTracks();
            tracks.forEach((track) => {
                if (peerConnectionRef.current) {
                    peerConnectionRef.current.addTrack(track, localStream);
                }
            });
        }
    
        if (peerConnectionRef.current) {
            peerConnectionRef.current.ontrack = (event) => {
                dispatch(setRemoteVideo(event.streams[0]));
            };
    
            peerConnectionRef.current.onicegatheringstatechange = (event) => {
                const connection = event.target as RTCPeerConnection;
                const reofferInprogress = false; // todo conditionally add this once we support re offer
                switch (connection && connection.iceGatheringState) {
                    case "gathering":
                        break;
                    case "complete":
                        const typePrefix = reofferInprogress ? "re" : "";
                        const localDescription = connection.localDescription;
                        if (localDescription) {
                            sendMessage({
                                type: typePrefix + localDescription.type,
                                sdp: localDescription.sdp,
                                target: String(unitId),
                                source: proflieId
                            });
                        }
                        break;
                }
            }
    
            // Define media constraints for offer
            const offerConstraints = {
                offerToReceiveAudio: true,
                offerToReceiveVideo: true,
            };
    
            const offer = await peerConnectionRef.current.createOffer(offerConstraints);
    
            await peerConnectionRef.current.setLocalDescription(offer);
        }
    };

    const endCall = (isDeclined = false, alexaDeclined = false) => {
        setIsCallAccepted(false);
        setIsCallIncoming(false);        
        setCaller({ callerId: null, callerName: null });
        setIsCallInProgress(false);
        setIsOutboundCallInitiated(false)
        setIsCallRinging(false);

        if (!alexaDeclined) {
            sendMessage({ type: "end", isDeclined });
        }

        if (peerConnectionRef.current) {
            // peerConnectionRef.current.getSenders().forEach(sender => sender.track && sender.track.stop());/
            peerConnectionRef.current.ontrack = null;
            peerConnectionRef.current.onicecandidateerror = null;
            peerConnectionRef.current.oniceconnectionstatechange = null;
            peerConnectionRef.current.onsignalingstatechange = null;
            peerConnectionRef.current.onicegatheringstatechange = null;
            peerConnectionRef.current.close();
            peerConnectionRef.current = null;
        }
        refreshSessionId();
        dispatch(setRemoteVideo(null));
        if (postEndCall) postEndCall()
    }


    const handleReOffer = async (msg: Message) => {
        try {
            setReOfferInprogress(true);
            peerConnectionRef.current = new RTCPeerConnection(iceStateServers);

            const peerConnection = new RTCPeerConnection(iceStateServers);
            peerConnectionRef.current = peerConnection;

            const localStream = getLocalStream();
            if (localStream && message) {
                peerConnection.ontrack = (event) => {
                    dispatch(setRemoteVideo(event.streams[0]));
                };

                peerConnection.onicecandidateerror = (event) => {
                    // Handle ICE candidate error event here
                    console.log("onicecandidateerror", event)
                }

                peerConnection.oniceconnectionstatechange = function (event) {
                    if (peerConnection.iceConnectionState === 'failed') {
                        // Handle ICE connection failed state here
                        console.log("oniceconnectionstatechange failed", event)
                    }
                };

                peerConnection.onsignalingstatechange = function (event) {
                    if (peerConnection.signalingState === 'closed') {
                        // Handle signaling state closed here
                        console.log("onsignalingstatechange closed", event)

                    }
                }

                peerConnection.onicegatheringstatechange = (event: any) => {
                    if (event && event.target && event.target.iceGatheringState === "complete" && event.target.localDescription) {
                        sendMessage({
                            type: "reoffer",
                            sdp: event.target.localDescription.sdp,
                            target: "65b2098f9ad12829298d9445", // todo add proper unit id value here.
                            source: proflieId
                        });
                    }
                }

                const remoteDescriptionMessage = {
                    type: "offer", 
                    sdp: msg.sdp,
                }

                // @ts-ignore
                await peerConnection.setRemoteDescription(remoteDescriptionMessage);
                localStream.getTracks().forEach(track => peerConnection.addTrack(track, localStream));

                const answer = await peerConnection.createAnswer();
                await peerConnection.setLocalDescription(answer);

                setupCodecPreferences(peerConnection);
            }
        } catch (error) {
            console.error("Error occurred while handling re offer", error);
            sendToast("error", "An error occurred while handling re offer");
        }
    }

    return [isCallIncoming, isOutboundCallInitiated, isCallAccepted, isCallInProgress, isReconnecting, startCall, endCall, handleIncomingCallAccecpt, caller, isCallRinging, sessionId] as const;
};

export default useWebSocket;
