import React, { useEffect, useState } from 'react';
import { Grid, Tab, TabProps } from 'semantic-ui-react';
import FacilityBasedServices from '../../../../components/Service/ServiceList';
import ServiceTemplateList from '../../../../components/Service/ServicesTemplateList';
import { withRouter } from 'react-router-dom';

const ServiceLibrary = ({ history }) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [tabLoading, setTabLoading] = useState<boolean>(false);
  useEffect(() => {
    tabFacilitation();
  }, []);

  const tabFacilitation = () => {
    const queryParams = new URLSearchParams(history.location.search); // choosing the tab
    const currentTab = queryParams.get('tab');
    let menuTabIndex = 0;
    if (currentTab === 'universal') {
      menuTabIndex = 2;
    } else if (currentTab === 'corporate') {
      menuTabIndex = 1;
    }
    setActiveIndex(menuTabIndex);
  };

  function handleMainTabChange(event: React.MouseEvent<HTMLDivElement, MouseEvent>, data: TabProps) {
    switch (data.activeIndex) {
      case 0: history.push('/admin/services/list?tab=facility');
        break;
      case 1: history.push('/admin/services/list?tab=corporate');
        break;
      case 2: history.push('/admin/services/list?tab=universal');
        break;
    }
    setActiveIndex(data.activeIndex as number);
  }

  const panes = [
    {
      menuItem: 'Community Services',
      render: () => (
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <FacilityBasedServices />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      ),
    },
    {
      menuItem: { key: 'tab1', content: 'Corporate Services', disabled: tabLoading },
      render: () => (
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <ServiceTemplateList source="Corporate" setTabLoading={setTabLoading} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      ),
    },
    {
      menuItem: { key: 'tab2', content: 'Universal Services', disabled: tabLoading },
      render: () => (
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <ServiceTemplateList source="Universal" setTabLoading={setTabLoading} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      ),
    },
  ];

  return (
    <div>
      <Tab panes={panes} menu={{ secondary: true, pointing: true }} activeIndex={activeIndex} onTabChange={handleMainTabChange} />
    </div>
  );
};

export default withRouter(ServiceLibrary);
