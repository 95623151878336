import React from 'react'
import UsersForm, { FormValues } from '../UsersForm'

import { withRouter, RouteComponentProps } from 'react-router'
import { Department, User, Facility, UserRole, UserProfile } from '../../../../types'
import { Dimmer, Loader, Modal } from 'semantic-ui-react'
import { fetchDepartments } from '../../../../services/Departments'
import { fetchOneUser, deleteUser, updateUser, fetchAssignableUserRoles, setNewPassword, resendUserPassword, reactivateUser } from '../../../../services/Users'
import { fetchAllActiveFacilities } from '../../../../services/Facilities'
import { isSysAdmin } from '../../../../services/Permissions';
import { Auth } from 'aws-amplify'
import SetNewPasswordModal from './setNewPassword'
import { toast } from 'react-toastify'
import { sendToast } from '../../../../util';

interface State {
    isFetching: boolean
    departments: Department[]
    facilities: Facility[]
    roles: UserRole[]
    user: User | null
    isSaving: boolean
    error: string | null
    newPasswordInputFields: boolean;
    PasswordReset: string;
    ConfirmPasswordReset: string;
    isSetPasswordModalOpen: boolean;
    isEmailSending: boolean;
}

interface RouteInfo {
    id: string
}

interface Props extends RouteComponentProps<RouteInfo> {
    profile: UserProfile | null;
    id: string;
    handleIsEdit: (isSubmit : boolean) => void;
}

class UserEdit extends React.Component<Props, State> {
    id: string
    constructor(props: Props) {
        super(props)
        this.state = {
            isSaving: false,
            error: null,
            departments: [],
            user: null,
            isFetching: false,
            facilities: [],
            roles: [],
            newPasswordInputFields: false,
            PasswordReset: '',
            ConfirmPasswordReset: '',
            isSetPasswordModalOpen: false,
            isEmailSending: false
        }
        this.id = props.id
    }

    async facilitiesPromise() {
        if (isSysAdmin(this.props.profile)) return fetchAllActiveFacilities()
        return Promise.resolve([])
    }

    async componentDidMount() {
        this.setState({ isFetching: true, error: null })
        try {
            const [user, departments, roles, facilities] = await Promise.all([
                fetchOneUser(this.props.id),
                fetchDepartments(),
                fetchAssignableUserRoles(),
                this.facilitiesPromise(),
            ])
            this.setState({ isFetching: false, user, departments, roles, facilities })
        } catch (e) {
            this.setState({ isFetching: false, error: e.message })
        }
    }

    closeEditTab(isSubmit = false) {
        this.props.handleIsEdit(isSubmit)
    }

    async handleSave(data: FormValues) {
        this.setState({ isSaving: true, error: null })
        try {
            if (!this.state.user) return
            // @ts-ignore
            if (!data.IsActive) {
                throw new Error("User is deactivated. Please reactivate user to save changes.")
            }
            if (this.state.newPasswordInputFields) {
                await this.forgotPasswordSubmit(data.VerificationCode, data.PasswordReset, data.ConfirmPasswordReset);
            }
            const updateData = { ...data };
            delete updateData.VerificationCode;
            delete updateData.PasswordReset;
            delete updateData.ConfirmPasswordReset;
            await updateUser({ _id: this.id, ...updateData }, this.state.user);
            this.setState({ isSaving: false });
            this.closeEditTab(true);
        } catch (e) {
            this.setState({ isSaving: false, error: e.message });
        }
    }

    async handleDelete(id: string) {
        this.setState({ isSaving: true, error: null })
        try {
            await deleteUser(id)
            this.setState({ isSaving: false })
            this.closeEditTab()
        } catch (e) {
            this.setState({
                isSaving: false,
                error: e.message,
            })
        }
    }

    async handleReactivateuser(id: string) {
        this.setState({ isSaving: true, error: null })
        try {
            await reactivateUser(id)
            this.setState({ isSaving: false })
            this.closeEditTab()
        } catch (e) {
            this.setState({
                isSaving: false,
                error: e.message,
            })
        }
    }

    async startPaswordReset(e): Promise<void> {
        e.preventDefault();
        try {
            if (this.state.user) {
                await Auth.forgotPassword(this.state.user.Username);
                this.openNewPasswordInput();
            }
        } catch (error) {
            if (error.message) error = error.message;
            this.setState({
                isSaving: false,
                error: error,
            })
        }
    }

    async setPasswordReset(e): Promise<void> {
        e.preventDefault();
    }

    onPasswordChange(stateProp: keyof State, value: string): void {
        this.setState({
            [stateProp]: value
        } as any, () => console.log(this.state[stateProp]));
    }

    async forgotPasswordSubmit(code, password, confirmPassword): Promise<void> {
        if (!code && !password && !confirmPassword) {
            return;
        }
        if (!this.state.user) throw new Error('User or username is not set.');
        await Auth.forgotPasswordSubmit(this.state.user.Username, code, password);
    }

    openNewPasswordInput(): void {
        this.setState({
            newPasswordInputFields: true
        });
    }

    openCaptureNewPasswordModal(): void {
        this.setState({
            isSetPasswordModalOpen: true
        })
    }

    closeCaptureNewPasswordModal(): void {
        this.setState({
            isSetPasswordModalOpen: false
        })
    }

    async handleSetNewPassword(password): Promise<void> {
        // Here make the API call
        // return new Promise(()=>{});
        if (this.state.user) {
            try {
                await setNewPassword(this.state.user._id, password);
                toast.success("Successfully set password", {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                this.closeCaptureNewPasswordModal()
            } catch (err) {
                console.log("Error!", err.message)
                toast.warn(err.message || "Something went wrong", {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                })
            }
        }
    }

    async resendTempPassword(): Promise<void> {
        try {
            if (this.state.user) {
                this.setState({
                    isEmailSending: true
                });
                const response = await resendUserPassword(String(this.state.user._id));
                if (response) {
                    sendToast("success", "Successfully resent temporary password to user");
                } else {
                    throw new Error("Failed to resend temporary password to user. Please try again later.");
                }
            } else {
                sendToast("warn", "User not found to resend temporary password. Please refresh the page and try again.");
            }
        } catch (error) {
           sendToast("error", "Failed to resend temporary password to user. Please try again later.");
        } finally {
            this.setState({
                isEmailSending: false
            });
        }
    }

    render() {
        return (
            <div className="UserEdit">
                <Dimmer active={this.state.isFetching} inverted>
                    <Loader active={this.state.isFetching} />
                </Dimmer>
                {this.state.user && (
                    <>
                        <UsersForm
                            title="Edit User"
                            onSave={this.handleSave.bind(this)}
                            cancel={this.closeEditTab.bind(this)}
                            captureNewPasswordModalOpen={this.openCaptureNewPasswordModal.bind(this)}
                            onDelete={this.handleDelete.bind(this)}
                            startPaswordReset={this.startPaswordReset.bind(this)}
                            isSysAdmin={isSysAdmin(this.props.profile)}
                            resendTempPassword={this.resendTempPassword.bind(this)}
                            reactivateUser={this.handleReactivateuser.bind(this)}
                            {...this.state}
                        />
                        <SetNewPasswordModal
                            isModalOpen={this.state.isSetPasswordModalOpen}
                            closeModal={this.closeCaptureNewPasswordModal.bind(this)}
                            updateNewPassword={this.handleSetNewPassword.bind(this)}
                        />
                    </>
                )}
            </div>
        )
    }
}

export default withRouter(UserEdit)
