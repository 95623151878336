import { fetchRegistrants, fetchResidentCalendarEvents } from '../../services/Registrants';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import { AuthState, Registrant, Request, ResidentCalendar } from '../../types';
import React from 'react';
import { Button, Dimmer, Dropdown, Grid, Icon, Loader, Modal, Popup, Segment, TextArea } from 'semantic-ui-react';
import { fetchAllStaffUsers } from '../../services/Users';
import { fetchResidentGroups } from '../../services/RoomGroups';
import Webcam from 'react-webcam';
import ListCalendar from '../ListCalendar';
import ResidentCalendarForm from '../ResidentCalendar/ResidentCalendarForm';
import { toast } from 'react-toastify';
import CreateRequestModal from '../Requests/CreateRequestModal';
import { fetchRequestInstance } from '../../services/RequestInstances';
import { sendToast } from '../../util';
import RequestsList from '../RequestsList';
import { RequestInstance } from '../../types/RequestInstance';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import LoadMore from '../LoadMore';
import { connect } from 'react-redux';
import CallList from './CallList';
import { CallHistory, CallNotesUpsert } from '../../types/CallHistory';
import { upsertCallHistoryNotes } from '../../services/CallHistory';
import { fetchStaffCalendarEvents } from '../../services/StaffCalendar';

type RoomOptions = {
    key: string;
    text: string;
    value: string;
};

interface State {
    showError: boolean;
    errorMessage: string;
    showResidentCalendarCreateModal: boolean;
    isLoadingResidentCalendar: boolean;
    residentCalendarDate: any;
    residentCalendarEntries: any;
    residentCalendarData: Partial<ResidentCalendar>;
    residentOptions: RoomOptions[] | [];
    staffOptions: RoomOptions[] | [];
    duration: number;
    isEdit: boolean;
    deleteResidentCalendarConfirmation: boolean;
    pageSource: string;
    openReqCreateModal: boolean;
    ordersLoading: boolean;
    orders: RequestInstance[];
    ordersPageNo: number;
    hasMoreOrders: boolean;
    selectedCallLog: CallHistory;
    selectedNotes: string;
    calendarUser: string;
    updatingNotes: boolean;
}
interface ResidentModalCalendarComponentProps {
    registrant: Registrant | null;
    videoRefCallback: any;
    isCallInProgress: boolean;
    sessionId: string;
    isOutboundCallInitiated: any;
    isCallIncoming: any;
    handleCameraError: any;
    videoConstraints: any;
    localStream: any;
    remoteVideo: any;
    
}

type Props = ResidentModalCalendarComponentProps & RouteComponentProps & {
    profile: AuthState['profile'];
};;

class ResidentModalCalendarComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showError: false,
            errorMessage: '',
            showResidentCalendarCreateModal: false,
            isLoadingResidentCalendar: false,
            residentCalendarEntries: [],
            residentCalendarDate: moment().hour(9).minute(0).second(0).toDate(),
            residentCalendarData: {},
            residentOptions: [],
            staffOptions: [],
            duration: 0,
            isEdit: false,
            deleteResidentCalendarConfirmation: false,
            pageSource: 'ResidentCalendar',
            openReqCreateModal: false,
            ordersLoading: false,
            orders: [],
            hasMoreOrders: false,
            ordersPageNo: 1,
            selectedCallLog: {} as CallHistory,
            selectedNotes: '',
            calendarUser: (this.props.registrant && this.props.registrant._id) ? this.props.registrant._id : this.props.profile && this.props.profile._id || '', // we might need to fetch the call history for the selected resident or the logged in staff.
            updatingNotes: false,
        };
    }

    setSelectCallLog = (callLog : CallHistory) => {
        this.setState({ selectedCallLog: callLog, selectedNotes: callLog.notes || '' });
    };

    setResdientCalendarDataField(key: keyof ResidentCalendar, value: any) {
        if (key === 'registrantIds') {
            if (Array.isArray(value)) {
                const handleGroups = value.map((residentId) => {
                    if (residentId.indexOf(',') !== -1) {
                        return residentId.split(',');
                    }
                    return residentId;
                });
                const rooms = handleGroups.flat();
                console.log({ rooms });
                this.setState({
                    residentCalendarData: {
                        ...this.state.residentCalendarData,
                        [key]: rooms,
                    },
                });
            }
            return;
        }
        this.setState(
            {
                residentCalendarData: {
                    ...this.state.residentCalendarData,
                    [key]: value,
                },
            },
            () => {
                // we need to update endDateTimeString whenever dateTimeString changes
                if (key === 'dateTimeString') {
                    const duration = this.state.duration;
                    const endDateTimeString = moment(this.state.residentCalendarData.dateTimeString)
                        .add(duration, 'm')
                        .format('YYYY-MM-DDTHH:mm:ss');
                    this.setResdientCalendarDataField('endDateTimeString', endDateTimeString);
                }
            },
        );
    }

    displayError = (errorMessage: string) => {
        if (errorMessage != "") {
            toast.error(errorMessage, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        }
    };

    fetchResidentCalendar = async (registrantId: string | null, date: Date = this.state.residentCalendarDate) => {
        this.setState({ isLoadingResidentCalendar: true });
        // fetch data for the seleted resident and date
        this.setState({
            residentCalendarDate: date,
        });
        const formattedDate = moment(date).format('YYYY-MM-DD');
        const startDate = formattedDate + 'T00:00:00'; // YYYY-MM-DDTHH:mm:ss
        const endDate = formattedDate + 'T23:59:59'; // YYYY-MM-DDTHH:mm:ss
        try {
            const data = await fetchResidentCalendarEvents(registrantId, startDate, endDate);
            this.setState({
                residentCalendarEntries: data,
            });
        } catch (error) {
            // do not throw error
            this.displayError('Unabled to fetch notepad events');
            console.error(error);
        }
        this.setState({ isLoadingResidentCalendar: false });
    };

    fetchStaffCalendar = async (staffId: string | null, date: Date = this.state.residentCalendarDate) => {
        // fetch data for the selected resident and date
        try {
            this.setState({
                isLoadingResidentCalendar: true,
                residentCalendarDate: date
            });
            if (!staffId || !date) throw new Error('StaffId or date is not set'); 
            const formattedDate = moment(date).format('YYYY-MM-DD');
            const startDate = formattedDate + 'T00:00:00'; // YYYY-MM-DDTHH:mm:ss
            const endDate = formattedDate + 'T23:59:59'; // YYYY-MM-DDTHH:mm:ss
            const data = await fetchStaffCalendarEvents(staffId, startDate, endDate);
            this.setState({
                residentCalendarEntries: data,
            });
        } catch (error) {
            // do not throw error
            this.displayError('Unable to fetch notepad events');
            console.error(error);
        } finally {
            this.setState({ isLoadingResidentCalendar: false });
        }
    };

    refreshResidentRooms = async () => {
        this.setState({ isLoadingResidentCalendar: true });
        // fetch residents of the facility and store in state
        try {
            const staffDetails = await fetchAllStaffUsers();
            const formatedStaffOptions = this.formatAndSortStaff(staffDetails);
            const residentsWithRooms = await fetchRegistrants();
            const formattedResidentOptions = this.formatAndSortResidents(residentsWithRooms);
            const roomGroups = await fetchResidentGroups();
            const sortedRoomGroups = this.formatAndSortRoomGroups(roomGroups);
            const roomOptions = [].concat(sortedRoomGroups).concat(formattedResidentOptions);
            this.setState({ residentOptions: roomOptions, staffOptions: formatedStaffOptions });
        } catch (error) {
            // do not throw error
            console.error(error);
            toast.error('Something went wrong', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        }
        this.setState({ isLoadingResidentCalendar: false });
    };

    formatAndSortResidents(residents) {
        const formattedResidents = residents.map((obj) => {
            return {
                key: `${obj._id}`,
                text:
                    obj.FirstName && obj.LastName
                        ? `${obj.FirstName} ${obj.LastName}`
                        : obj.FirstName
                            ? `${obj.FirstName}`
                            : `${obj.FirstName}`,
                value: `${obj._id}`,
            };
        });

        const sortedResidents = formattedResidents.sort((a, b) => {
            const A = a.text.toUpperCase();
            const B = b.text.toUpperCase();

            if (A < B) {
                return -1;
            }
            if (A > B) {
                return 1;
            }
            return 0;
        });

        return sortedResidents;
    }

    formatAndSortStaff = (staff) => {
        const formattedStaffs = staff.map((obj) => {
            return {
                key: `${obj._id}`,
                text:
                    obj.FirstName && obj.LastName
                        ? `${obj.FirstName} ${obj.LastName}`
                        : obj.FirstName
                            ? `${obj.FirstName}`
                            : `${obj.LastName}` || "",
                value: `${obj._id}`,
            };
        });

        const sortedStaffs = formattedStaffs.sort((a, b) => {
            const A = a.text.toUpperCase();
            const B = b.text.toUpperCase();
            if (A < B) {
                return -1;
            }
            if (A > B) {
                return 1;
            }
            return 0;
        });

        return sortedStaffs;
    };

    formatAndSortRoomGroups = (groups: any) => {
        const formattedRoomGroups = groups
            .map((obj) => {
                if (obj.ResidentIds && obj.ResidentIds.length <= 1) return; // don't show groups which does not have atleast 2 ResidentIds (To take care of wierd issues with residentGroups)
                return {
                    key: `${obj._id}`,
                    text: `${obj.Name} group`,
                    value: `${[obj.ResidentIds]}`,
                };
            })
            .filter((obj) => obj);

        const sortedRoomGroups = formattedRoomGroups.sort((a, b) => {
            const A = a.text.toUpperCase();
            const B = b.text.toUpperCase();

            if (A < B) {
                return -1;
            }
            if (A > B) {
                return 1;
            }
            return 0;
        });

        return sortedRoomGroups;
    };

    resetResidentCalendarData = () => {
        this.setState({
            residentCalendarData: {},
            isEdit: false,
        });
    };

    refreshResidentCalendar = async () => {
        try {
            await this.fetchResidentCalendar(this.props.registrant && this.props.registrant._id, this.state.residentCalendarDate);
        } catch (error) {
            console.error(error);
            this.displayError('Unable to refresh notepad');
        }
    };

    setShowResidentCalendarModal = (show: boolean, isEdit: boolean, dateRange: { start: string, end: string; } | null = null, event: ResidentCalendar | null = null) => {
        if (isEdit) {
            if (event) {
                const duration = event.endDateTimeString
                    ? Math.floor((Date.parse(event.endDateTimeString) - Date.parse(event.dateTimeString)) / 1000 / 60)
                    : 0;
                this.setState({
                    residentCalendarData: {
                        ...this.state.residentCalendarData,
                        ...event,
                    },
                    duration: duration,
                    isEdit: true,
                });
            }
        } else {
            if (dateRange) {
                this.setState({
                    residentCalendarData: {
                        ...this.state.residentCalendarData,
                        dateTimeString: dateRange.start,
                        endDateTimeString: dateRange.end,
                    },
                    isEdit: false,
                });
            }
        }
        this.setState({
            showResidentCalendarCreateModal: show,
        });
    };

    getDropdownOptions = () => {
        const options = [
            { key: 'my-calendar', text: 'My Calendar', value: this.props.profile && String(this.props.profile._id) || "" }
        ];

        if (this.props.registrant &&
            this.props.registrant._id &&
            this.props.registrant._id) {
            options.push({
                key: 'selected-resident',
                text: `Resident Calendar`,
                value: this.props.registrant &&
                    this.props.registrant._id &&
                    String(this.props.registrant._id)
            });
        }

        return options;
    };


    async fetchOrdersForSelectedResident(residentId: string | null = null) {
        try {
            if (!residentId || !(this.props.profile && this.props.profile.FacilityConfigurations && this.props.profile.FacilityConfigurations.orders)) {
                return;
            }
            this.setState({ ordersLoading: true });
            const params = {
                Filter: {
                    Status: ['Open', 'Accepted', 'Closed'],
                    Registrant: this.props.registrant && String(this.props.registrant._id),
                },
                QueryOptions: {
                    page_no: 1,
                    page_size: this.state.ordersPageNo * 5,
                    sort: [
                        {
                            RequestedTime: 'asc',
                        },
                    ],
                },
            };
            const orders = await fetchRequestInstance(params);
            if (orders && orders.Result && orders.Result.length > 0 && orders.TotRecords) {
                if (orders.Result.length < orders.TotRecords) {
                    this.setState({ hasMoreOrders: true });
                } else {
                    this.setState({ hasMoreOrders: false });
                }
                this.setState({ orders: orders.Result });
            } else {
                this.setState({ orders: [], hasMoreOrders: false });
            }
        }
        catch (error) {
            sendToast('error', error instanceof Error ? error.message : 'Something went wrong in fetching orders');
        } finally {
            this.setState({ ordersLoading: false });
        }
    }

    goToSingle(id: string, req?: Request) {
        this.props.history.push({
            pathname: `/admin/request/${id}`,
            state: { similarRequestIds: req && req.similarRequestIds }
        });
    }

    async componentDidMount() {
        try {
            await Promise.all([
                this.refreshResidentRooms(),
                ((this.state.calendarUser === (this.props.profile && this.props.profile._id)) ? this.fetchStaffCalendar(this.props.profile && this.props.profile._id, new Date()) : this.fetchResidentCalendar(this.props.registrant && this.props.registrant._id, new Date())),
                this.fetchOrdersForSelectedResident(this.props.registrant && this.props.registrant._id),
            ]);
        } catch (error) {
            console.error(error);
            this.displayError('Unable to refresh notepad');
        }
        if (this.props && this.props.registrant) {
            this.setState({ isLoadingResidentCalendar: true });
            const currentResident = this.state.residentOptions.find(
                (resident) => resident.key === (this.props && this.props.registrant && this.props.registrant._id),
            );
            if (currentResident && typeof currentResident.value === 'string') {
                this.setState({
                    residentCalendarData: {
                        ...this.state.residentCalendarData,
                        registrantIds: [currentResident.value],
                    },
                });
            }
            this.setState({ isLoadingResidentCalendar: false });
        }
    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
        if (prevState.ordersPageNo !== this.state.ordersPageNo) {
            await this.fetchOrdersForSelectedResident(this.props.registrant && this.props.registrant._id);
        }
        if (prevState.calendarUser !== this.state.calendarUser) {
            if (this.state.calendarUser == (this.props.profile && this.props.profile._id)) {
                await this.fetchStaffCalendar(this.props.profile && this.props.profile._id, this.state.residentCalendarDate);
            } else {
                await this.fetchResidentCalendar(this.props.registrant && this.props.registrant._id, this.state.residentCalendarDate);
            }
        }
        if (prevProps.registrant !== this.props.registrant) {
            this.props.registrant && this.setState({ calendarUser: String(this.props.registrant._id) });
        }
    }

    async upsertCallNotes() {
        try {
            this.setState({ updatingNotes: true });
            const upsertData: CallNotesUpsert = {
                notes: this.state.selectedNotes
            };
            if (this.props.isCallInProgress) {
                upsertData.sessionId = this.props.sessionId;
            } else {
                upsertData.callHistoryId = this.state.selectedCallLog && String(this.state.selectedCallLog._id);
            }
            await upsertCallHistoryNotes(upsertData);
            this.setSelectCallLog({ ...this.state.selectedCallLog, notes: this.state.selectedNotes });
            sendToast('success', "Successfully updated notes");
        } catch (err) {
            sendToast('error', err instanceof Error ? err.message : 'Failed to update notes');
        } finally {
            this.setState({ updatingNotes: false });
        }
    }

    render() {
        return (
            <>
                <ResidentCalendarForm
                    setLoader={(value: boolean) => this.setState({ isLoadingResidentCalendar: value })}
                    showModal={this.state.showResidentCalendarCreateModal}
                    closeForm={() => this.setState({ showResidentCalendarCreateModal: false })}
                    isEdit={this.state.isEdit}
                    residentCalendarData={this.state.residentCalendarData}
                    setResdientCalendarDataField={this.setResdientCalendarDataField.bind(this)}
                    displayError={this.displayError}
                    refresh={this.refreshResidentCalendar}
                    resetResidentCalendarData={this.resetResidentCalendarData}
                    residentOptions={this.state.residentOptions}
                    staffOptions={this.state.staffOptions}
                    duration={this.state.duration}
                    setDuration={(duration: number) => this.setState({ duration })}
                    isStaff={false}
                    pageSource={this.state.pageSource}
                />
                <Grid>
                    <Grid.Row columns={2} style={{ margin: "10px 0px 0px 0px"}} >
                        <Grid.Column width={10}>
                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    paddingTop: '52.25%',
                                    position: 'relative',
                                }}
                            >
                                {this.props.remoteVideo ? (
                                    <video
                                        ref={this.props.videoRefCallback}
                                        autoPlay
                                        playsInline
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                        }}
                                    />
                                ) : (
                                    <></>
                                )}
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        background: '#9a949480',
                                        objectFit: 'cover',
                                    }}
                                ></div>
                            </div>
                            <div
                                style={{
                                    position: 'absolute',
                                    bottom: '8px',
                                    right: '20px',
                                    width: '120px',
                                    height: '90px',
                                    borderRadius: '5px',
                                    zIndex: 100,
                                    overflow: 'hidden',
                                }}
                            >
                                {(this.props.registrant ||
                                    this.props.isCallIncoming ||
                                    this.props.isCallInProgress) && (
                                        <Webcam
                                            audio={true}
                                            onUserMediaError={this.props.handleCameraError}
                                            videoConstraints={this.props.videoConstraints}
                                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                            onUserMedia={(stream) => {
                                                this.props.localStream.current = stream;
                                            }}
                                        />
                                    )}
                            </div>
                        </Grid.Column>
                        <Grid.Column width={6} style={{
                            height: '30vw',
                            overflowY: 'hidden',
                            overflowX: 'hidden',
                        }} >
                            <div className='calendarRow' >
                                {(this.props.registrant || (this.props.profile && this.props.profile._id)) && (
                                    <>
                                        <div className='alignItemsCenter'>
                                            <Popup
                                                trigger={<Button className='searchBtnPlus' icon='plus' size='large' onClick={() => this.setShowResidentCalendarModal(true, false)} loading={false} />}
                                                content="Create a calendar event"
                                            />
                                            <Dropdown className='headerFontSize'
                                                fluid
                                                search
                                                selection
                                                value={this.state.calendarUser}
                                                options={this.getDropdownOptions()}
                                                onChange={(event, { value }) => { this.setState({ calendarUser: value as string }); }}
                                                style={{ width: '55%', marginRight: '5px' }}
                                            />
                                            <div style={{ width: "45%", padding: '0' }}>
                                                <DatePicker
                                                    customInput={
                                                        <div style={{ position: 'relative' }}>
                                                            <Icon
                                                                name="calendar alternate outline"
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: '45%',
                                                                    left: '10px',
                                                                    transform: 'translateY(-50%)',
                                                                }}
                                                            />
                                                            <input
                                                                style={{ border: '1px double #183466', borderRadius: "5px", padding: '10px 10px 10px 30px' }}
                                                                placeholder="Start time"
                                                                value={moment(this.state.residentCalendarDate).format('M.D.Y')}
                                                            />
                                                        </div>
                                                    }
                                                    onChange={async (date: Date) => {
                                                        (this.state.calendarUser == (this.props.profile && this.props.profile._id)) ?
                                                            await this.fetchStaffCalendar(
                                                                this.props.profile && this.props.profile._id,
                                                                date,
                                                            ) :
                                                            await this.fetchResidentCalendar(
                                                                this.props &&
                                                                this.props.registrant &&
                                                                this.props.registrant._id &&
                                                                this.props.registrant._id,
                                                                date,
                                                            );
                                                    }}
                                                    selected={this.state.residentCalendarDate}
                                                    onMonthChange={async (date) => {
                                                        this.setState({
                                                            residentCalendarDate: date,
                                                        });
                                                        await this.fetchResidentCalendar(
                                                            this.props &&
                                                            this.props.registrant &&
                                                            this.props.registrant._id &&
                                                            this.props.registrant._id,
                                                            date,
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <Dimmer active={this.state.isLoadingResidentCalendar} inverted>
                                            <Loader />
                                        </Dimmer>

                                        <ListCalendar events={this.state.residentCalendarEntries} currentDay={this.state.residentCalendarDate} setShowResidentCalendarModal={this.setShowResidentCalendarModal} />
                                    </>
                                )}
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{ margin: "10px 0px 10px 0px" }}>
                        <Grid.Column width={16} >
                            <Grid stretched stackable>
                                {this.props.registrant && (
                                    <Grid.Column width={8}>
                                        <Segment style={{ padding: '10px' }}>
                                            <Dimmer active={this.state.ordersLoading} inverted>
                                                <Loader active={this.state.ordersLoading} />
                                            </Dimmer>
                                            {this.props.profile && this.props.profile.FacilityConfigurations && this.props.profile.FacilityConfigurations.orders ?
                                                <>
                                                    <div className="flexbox">
                                                        <p className="orderHeading headerFontSize">Quick Order</p>
                                                        <div
                                                            className="create-request"
                                                            onClick={() => {
                                                                this.setState({ openReqCreateModal: true });
                                                            }}
                                                        >
                                                            <Icon name="add" className="button-icon" />
                                                        </div>
                                                    </div>
                                                    {this.state.orders.length > 0 ? <>
                                                        <RequestsList
                                                            requests={this.state.orders}
                                                            unassigned={false}
                                                            powerAndFacilityUsers={true}
                                                            handleClick={this.goToSingle.bind(this)}
                                                            refresh={this.fetchOrdersForSelectedResident.bind(this, this.props.registrant._id)}
                                                            isCompactView={true} />
                                                        <LoadMore
                                                            isLoading={this.state.ordersLoading}
                                                            hasMore={this.state.hasMoreOrders || false}
                                                            next={() => this.setState({ ordersPageNo: this.state.ordersPageNo + 1 })}
                                                            skipMargin={true} />
                                                    </> :
                                                        <h4 className='centered'>No orders found</h4>}
                                                </>
                                                :
                                                <h3 className='centered'>Orders are disabled for this community</h3>
                                            }
                                        </Segment>
                                    </Grid.Column>
                                )}
                                <Grid.Column width={8} floated='right' >
                                    <Segment style={{ padding: '10px' }}>
                                        <div className='flexbox'>
                                            <p className='headerFontSize'> Call History </p>
                                        </div>
                                        <Grid>
                                            <Grid.Row padded columns={2}>
                                                <Grid.Column width={6}>
                                                    <CallList
                                                        selectedCallLog={this.state.selectedCallLog}
                                                        setSelectCallLog={(callLog) => this.setSelectCallLog(callLog)}
                                                        {...((this.props.registrant && this.props.registrant._id) ? { residentId: String(this.props.registrant._id) } : { staffId: (this.props.profile && this.props.profile._id) })}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column width={10} style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <TextArea
                                                        placeholder='Notes'
                                                        className="headerFontSize"
                                                        style={{
                                                            height: '80%',
                                                            width: '100%',
                                                            padding: '20px',
                                                            resize: 'vertical' // This line restricts resizing to only the vertical direction
                                                        }}
                                                        value={this.state.selectedNotes}
                                                        onChange={(event, data) => this.setState({ selectedNotes: data.value as string })}
                                                    />
                                                    <div style={{ marginTop: "10px" }}>
                                                        <Button
                                                            onClick={() => this.upsertCallNotes()}
                                                            disabled={((this.state.selectedCallLog._id || this.props.isCallInProgress) ? false : true) || this.state.updatingNotes}
                                                            loading={this.state.updatingNotes}
                                                        >
                                                            {this.props.isCallInProgress ? "Update for live call" : "Update"}
                                                        </Button>
                                                    </div>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Segment>
                                </Grid.Column>
                            </Grid>
                        </Grid.Column >
                    </Grid.Row>
                </Grid>
                <Modal
                    open={this.state.openReqCreateModal}
                    onClose={() => this.setState({ openReqCreateModal: false })}
                    onOpen={() => this.setState({ openReqCreateModal: true })}
                    style={{ width: '450px' }}
                >
                    <Modal.Content>
                        <CreateRequestModal
                            setOpen={() => this.setState({ openReqCreateModal: false })}
                            resident={(this.props.registrant && this.props.registrant._id) || undefined}
                            refresh={this.fetchOrdersForSelectedResident.bind(this, this.props.registrant && this.props.registrant._id)}
                        />
                    </Modal.Content>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state: { authReducer: AuthState; }) => ({
    profile: state.authReducer.profile,
});

export default connect(mapStateToProps)(withRouter(ResidentModalCalendarComponent));