import { CallNotesUpsert } from '../types/CallHistory';
import { fetchAllPaginatedData } from '../util';
import API from './API';

export async function ListPaginatedResidentCallHistory(filter = {}, page_no = 1, page_size = 500): Promise<any> {
    try {
        const Filter = filter;

        const QueryOptions = {
            page_no,
            page_size,
            sort: [{ startTimestamp: "asc" }], // sort by Name in ascending order
        };

        const res = await API.lambdaPost('/call-history/list', { Filter, QueryOptions });
        return res;

    } catch (e) {
        throw new Error(e instanceof Error ? e.message : 'Failed to fetch call history of resident');
    }
}

const callLogsApiCall = async (params: any) => { return await API.lambdaPost('/call-history/list', params); };

export async function ListAllResidentCallHistory(Filter = {}): Promise<any> {
    try {
        const res = await fetchAllPaginatedData(callLogsApiCall, { Filter }, [{ startTimestamp: "asc" }]);
        return res;
    } catch (e) {
        throw new Error(e instanceof Error ? e.message : 'Failed to fetch call history of staff');
    }
}

export async function upsertCallHistoryNotes(upsertData: CallNotesUpsert): Promise<any> {
    try {
        if (!(upsertData.callHistoryId || upsertData.sessionId)) {
            throw new Error('Either callHistoryId or sessionId is required');
        }
        const res = await API.lambdaPost('/call-history/notes/upsert', upsertData);
        return res;
    } catch (e) {
        throw new Error(e instanceof Error ? e.message : 'Failed to upsert call notes');
    }
}