import React, { useEffect, useReducer, useRef, useState } from 'react';
import {
    Form,
    Input,
    Checkbox,
    Select,
    Tab,
    SemanticShorthandItem,
    TabPaneProps,
    TextArea,
    Modal,
    Button,
    Grid,
    Dimmer,
    Loader,
    Popup,
    CheckboxProps,
    Segment,
    Divider,
} from 'semantic-ui-react';
import ProfilePanes from './ProfilePanes';
import useCheckbox from '../ResidentProfile/useCheckbox';
import { reqInitialState, reqReducer } from '../../pages/AdminPanel/Services/reducersData';
import Requirements from '../../pages/AdminPanel/Services/Requirements';
import DietaryPanes from './DietaryPanes';
import Details from '../../pages/AdminPanel/Services/Details';
import { fetchServiceTypesImage, fetchUniversalServiceTypesImage, getServiceCategories, updateCorporateService, updateUniversalService, uploadServiceImage, uploadUniversalServiceImage, upsertService } from '../../services/service';
import FilePicker from '../S3FileUpload/FilePicker';
import styles from '../../pages/AdminPanel/Signage/Background.module.css';
import { listAllRestaurant, listAssets } from '../../services/Assets';
import { Asset } from '../../types/Asset';
import { useSelector } from 'react-redux';
import { AuthState, Department, Registrant, User } from '../../types';
import Docs from '../../pages/AdminPanel/Services/Docs';
import { toast } from 'react-toastify';
import ListDocsModal from '../AssetsGroupsManagement/ListDocsModal';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { fetchActiveUsers } from '../../services/Users';
import { isFacilityAdmin, isPowerUser } from '../../services/Permissions';
import { DetailsState, DocWidgetData, Service, ServiceAddOn, TaxRateType } from '../../types/Service';
import { getDocWidgetData } from '../../services/DocWidgets';
import { fetchFacilityRegistrants } from '../../services/Registrants';
import { listGroups } from '../../services/Groups';
import { Group } from '../../types/Group';
import { fetchDepartments } from '../../services/Departments';
import TaxTab from './TaxTab';
import { sortByKey } from '../../util/sortData';
import ServiceAddOns from './ServiceAddOns';
import { mealTypes, sendToast, templateService } from '../../util';
import ServiceMealRestrictions from '../ServiceMealRestrictions';
import useServiceDietery from '../ServiceMealRestrictions/reducerData';
import { cookbook } from '../../util';
import { removeUnsafeElementsFromFileName } from '../../util/image';


interface ServicesTypesFormProps {
    service?: Partial<Service>; // Optional prop for existing service data
    onUpdateService?: (updatedService: Partial<Service>) => void;
    source?: string;
    templateId?: string;    
}

interface UploadState {
    percentage: number;
    isUploading: boolean;
    finished: boolean;
    error: string | null;
}

const ServicesTypesForm: React.FC<ServicesTypesFormProps & RouteComponentProps<any>> = ({ service, history, source, templateId }) => {
    const defaultService: Partial<Service> = {
        active: true,
        bookable: false,
        category: '',
        longDescription: '',
        name: '',
        shortDescription: '',
        url: '',
        universal: false,
        corporate: false,
        image: '',
        Asset: '',
        SecondaryAssets: [],
        DocId: '',
    };

    const [uploadState, setUploadState] = useState<UploadState>({ // this state is used to pass as props in FilePicker and setUploadState functon is  not being used in the current implementation because the image is only uploaded when the save button is clicked, not when a file is selected.
        percentage: 0,
        isUploading: false,
        finished: false,
        error: null,
    });

    const [formValues, setFormValues] = useState<Partial<Service>>(service || defaultService);
    const [defaultAssets, setDefaultAssets] = useState<Asset[]>([]);
    const [imageFile, setImageFile] = useState<File | null>(null);
    const [checkboxItems, dispatch, loading] = useCheckbox('', true, service || undefined);
    const [reqState, reqDispatch] = useReducer(reqReducer, reqInitialState);
    const [dieteryState, dieteryDispatch] = useServiceDietery(service && service.mealRestrictions || undefined)
    const [staffList, setStaffList] = useState<User[]>([]);
    const [residentsList, setResidentsList] = useState<Registrant[]>([]);
    const [groupsList, setGroupsList] = useState<Group[]>([]);
    const [details, setDetails] = useState<DetailsState>({
        activeStartDate: '',
        activeEndDate: '',
        createdBy: '',
        updatedBy: '',
        billingCode: '',
        integrationKey: '',
        uniqueId: '',
    });
    const [imageUrl, setImageUrl] = useState<string>('');
    const [docId, setDocId] = useState<string>('');
    const [docsModal, setDocsModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [localImgPath, setLocalImgPath] = useState<string | null>('');
    const previewImgRef = useRef<any>({
        current: {
            clientHeight: 0,
            clientWidth: 0,
        },
    });
    const [open, setOpen] = useState<boolean>(false);
    const profile = useSelector(({ authReducer }: { authReducer: AuthState }) => {
        return authReducer.profile;
    });
    const [fetchingImage, setFetchingImage] = useState<boolean>(false);
    const FacilityAdmin: boolean = isFacilityAdmin(profile);
    const PowerUser: boolean = isPowerUser(profile);
    const [addOns, setAddOns] = useState<ServiceAddOn[]>([]);
    const [allDocs, setAllDocs] = useState<DocWidgetData[]>([]);
    const [departments, setDepartments] = useState<Department[]>([]);
    const [docsLoading, setDocsLoading] = useState<boolean>(false);
    const [taxRates, setTaxRates] = useState<TaxRateType>({ federal: "", state: "", local: "", other: "" });
    const [categoryOptions, setCategoryOptions] = useState<string[]>([]);

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                const [assets, users, residents, groups, categories] = await Promise.all([source === cookbook ? listAllRestaurant() : listAssets(), fetchActiveUsers(), fetchFacilityRegistrants(), listGroups(), getServiceCategories({ sortAlphabetically: true })]);
                const departmentsArr = await fetchDepartments();
                if (source === cookbook && departmentsArr.length > 0) {
                    const diningDepartment = departmentsArr.find((department) => department.Name === "Dining");
                    if (!diningDepartment) {
                        sendToast('error', 'Dining Department not found');
                        return;
                    }
                    setFormValues({ ...formValues, ['Department']: String(diningDepartment._id), ["category"]: "Menu", ["active"]: true });
                }
                setDefaultAssets(assets);
                setStaffList(users);
                setResidentsList(residents);
                setGroupsList(groups);
                setDepartments(departmentsArr);
                setCategoryOptions(categories);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                toast.error('Something went wrong, please try again', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                console.error('error fetching assets', error);
            }
        })();
    }, []);

    useEffect(() => {
        const fetchImage = async () => {
            if (service && service._id && service.image && profile) {
                try {
                    setFetchingImage(true); // Start fetching
                    if (source === templateService) {
                        const imageUrl = await fetchUniversalServiceTypesImage(service._id, service.image);
                        setImageUrl(imageUrl);
                    } else {
                        const imageUrl = await fetchServiceTypesImage(service._id, service.image, profile.Facility);
                        setImageUrl(imageUrl);
                    }
                } catch (error) {
                    toast.error('Something went wrong , please try again', {
                        position: 'bottom-center',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                } finally {
                    setFetchingImage(false); // Done fetching
                }
            } else {
                setImageUrl('');
            }
            setLocalImgPath(null);
        };
        fetchImage();
    }, [service]);

    useEffect(() => {
        const fetchTemplateImage = async () => {
            if (templateId && service && service.image) {
                try {
                    setFetchingImage(true); // Start fetching
                    const imageUrl = await fetchUniversalServiceTypesImage(templateId, service.image);
                    const file = await convertUrlToFile(imageUrl, service.image);
                    handleFileSelect([file]);
                } catch (error) {
                    toast.error('Something went wrong , please try again', {
                        position: 'bottom-center',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                } finally {
                    setFetchingImage(false); // Done fetching
                }
            }
        };
        fetchTemplateImage();
    }, [templateId]);
    

    useEffect(() => {
        if (service) {
            setFormValues(service);
            setDocId(service.DocId || '');
            setDetails({
                activeStartDate: (service.details && service.details.activeStartDate) || '',
                activeEndDate: (service.details && service.details.activeEndDate) || '',
                createdBy: (service.details && service.details.createdBy) || '',
                updatedBy: (service.details && service.details.updatedBy) || '',
                billingCode: (service.details && service.details.billingCode) || '',
                integrationKey: (service.details && service.details.integrationKey) || '',
                uniqueId: (service.details && service.details.uniqueId) || '',
            });
            reqDispatch({
                type: 'SET_DEFAULTS',
                payload: service.defaults,
            });
            if (service.ServiceAddOns) {
                setAddOns(service.ServiceAddOns);
            }
            if (service.taxRates) {
                setTaxRates({
                    federal: (service.taxRates && service.taxRates.federal) || '',
                    state: (service.taxRates && service.taxRates.state) || '',
                    local: (service.taxRates && service.taxRates.local) || '',
                    other: (service.taxRates && service.taxRates.other) || '',
                });
            }
        } else {
            reqDispatch({
                type: 'RESET_STATE',
            });
            setDocId('');
            setAddOns([]);
        }
    }, [service]);

    useEffect(() => {
        if (service && service.Asset && defaultAssets.length > 0) {
            const asset: Asset | undefined = defaultAssets.find((asset: Asset) => asset._id === service.Asset);
            if (asset && asset.DocId) {
                fetchDocsDetails(asset.DocId);
            }
        }
    }, [defaultAssets]);

    useEffect(() => {
        setFormValues({ ...formValues, ['image']: imageFile ? imageFile.name : '' });
    }, [imageFile]);


    const categoriesDropdownOptions = categoryOptions && categoryOptions.length > 0 && categoryOptions.map((category) => {
        return {
            key: category,
            text: category,
            value: category
        };
    }
    ) || [];

    const isFormValid = () => {
        const isCommonFieldsValid =
            !!formValues.name &&
            !!formValues.shortDescription &&
            !!formValues.category &&
            !!formValues.Asset &&
            !!formValues.Department;

        if (source === cookbook) {
            return (
                isCommonFieldsValid &&
                !!formValues.mealTypes &&
                !!formValues.mealTypes.length
            );
        }

        if (source === templateService) {
            return (
                !!formValues.name &&
                !!formValues.shortDescription &&
                !!formValues.category &&
                !!formValues.Department
            );
        }

        return isCommonFieldsValid;
    };    

    const resetFormData = () => {
        reqDispatch({
            type: 'RESET_STATE',
        });
        dispatch({
            type: 'RESET_SERVICE_STATE',
        });
        setDocId('');
        setDetails({
            activeStartDate: '',
            activeEndDate: '',
            createdBy: '',
            updatedBy: '',
        });
        setFormValues({
            active: true,
            bookable: false,
            category: '',
            longDescription: '',
            name: '',
            shortDescription: '',
            url: '',
            universal: false,
            image: '',
            Asset: '',
            docs: '',
        });
        setImageFile(null);
        setAddOns([]);
        setTaxRates({ federal: "", state: "", local: "", other: "" });
    };

    const handleFormSubmit = async () => {
        try {
           
            const {
                Dairy,
                Protein,
                Vegetables,
                PlantProteins,
                Fruits,
                GrainsAndStarches,
                Other,
                ...wellnessCategories
            } = checkboxItems;
            const combinedObj = {
                ...formValues,
                Wellness: { ...wellnessCategories },
                defaults: { ...reqState },
                details: { ...details },
                DocId: docId,
                ServiceAddOns:addOns, 
                Dietary: { Dairy, Protein, Vegetables, PlantProteins, Fruits, GrainsAndStarches, Other },
                taxRates: taxRates,
                mealRestrictions: {...dieteryState}
            };
            setIsLoading(true);
            let serviceData: Service;
            // If there's no new image selected, preserve the existing image value
            if (!imageFile && service && service.image) {
                combinedObj.image = service.image;
            }
            if (service && service._id) {
                if (source === templateService) { //Updating the template
                    serviceData = combinedObj.CorporateId ? await updateCorporateService({ ...combinedObj, _id: service._id }) : await updateUniversalService({ ...combinedObj, _id: service._id });
                } else { 
                    serviceData = await upsertService({ ...combinedObj, _id: service._id });
                }  
            } else {
                serviceData = await upsertService(combinedObj);
            }
            if (imageFile && serviceData && profile) {
                if (service && service._id) {
                    if (source === templateService) { //Updating
                        await uploadUniversalServiceImage(imageFile, service._id,);
                    } else {
                        await uploadServiceImage(imageFile, service._id, profile.Facility, (combinedObj.universal || combinedObj.corporate) ? true : false);
                    }
                } else {
                    await uploadServiceImage(imageFile, serviceData._id, profile.Facility, (combinedObj.universal || combinedObj.corporate) ? true : false);
                }
            }
            if (service && service._id) {
                toast.success('Successfully updated service', {
                    position: 'bottom-center',
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            } else {
                // If it's a new service, we reset the form as usual.
                resetFormData();
                history.push(source === cookbook ? '/admin/cookbook' : '/admin/services/list');
                toast.success('Successfully Created Service', {
                    position: 'bottom-center',
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
        } catch (error) {
            console.error('error handleFormSubmit', error);
            const errorMessage =  error instanceof Error && error.message ? error.message : 'Something went wrong, please try again';
            toast.error(errorMessage, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleInputChange = (event, { name, value, checked }) => {
        if (name === 'Asset') {
            // Find the selected asset from the defaultAssets array
            const selectedAsset = defaultAssets.find(asset => asset._id === value);
            if (selectedAsset) {
                const secondaryAssets = formValues.SecondaryAssets || [];
                const isPrimaryAssetInSecondaryAssets = secondaryAssets.includes(selectedAsset._id);
                if (isPrimaryAssetInSecondaryAssets) {
                    toast.warn('Please remove this asset from secondary assets to use it in default asset.', {
                        position: 'bottom-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    return;
                }
                // Set internalCostRequirement and externalCostRequirement based on the selected asset's properties
                reqDispatch({
                    type: 'HANDLE-INPUT-CHANGE',
                    payload: {
                        key: 'internalCostRequirement',
                        value: String(selectedAsset.MinCap),
                    },
                });
                reqDispatch({
                    type: 'HANDLE-INPUT-CHANGE',
                    payload: {
                        key: 'externalCostRequirement',
                        value: String(selectedAsset.MaxCap),
                    },
                });
                if (selectedAsset.DocId) {
                    fetchDocsDetails(selectedAsset.DocId);
                }
            }
        }

        if (name === "corporate") {
            if (checked) {
                if (!(profile && profile.CorporateId)) {
                    sendToast('warn', 'The Facility does not have a Corporate associated with it.');
                    return;
                }
                setFormValues({ ...formValues, universal: false, corporate: true });

            } else {
                setFormValues({ ...formValues, universal: true, corporate: false });
            }
            return;
        } else if (name === "universal") {
            if (checked) {
                setFormValues({ ...formValues, corporate: false, universal: true });
            } else {
                setFormValues({ ...formValues, corporate: false, universal: false });
            }
            return;
        }
        setFormValues({ ...formValues, [name]: checked !== undefined ? checked : value });
    };

    const handleCheckboxChange = (
        event: React.FormEvent<HTMLInputElement>, // Specify the event type
        { name, checked }: CheckboxProps // Destructure CheckboxProps and specify the expected properties
      ) => {
        if (name === 'autoAnnouncement' || name === 'autoNotification') {
            reqDispatch({
                type: "HANDLE-INPUT-CHANGE",
                payload: {
                    key: name,
                    value: checked as boolean
                }
            });
        } else if (name === 'alertTarget') {
           if(checked) {
            reqDispatch({
                type: "HANDLE-INPUT-CHANGE",
                payload: {
                    key: 'alertTarget',
                    value: 'all'
                }
            });
           } else { 
            reqDispatch({
                type: "HANDLE-INPUT-CHANGE",
                payload: {
                    key: 'alertTarget',
                    value: 'registered'
                }
            });
           }
        } else {
            reqDispatch({
                type: "HANDLE-INPUT-CHANGE",
                payload: {
                    key: name as string,
                    value: checked as boolean
                }
            });
        }
    };

    const handleFileSelect = (files: File[]) => {
        const file = files[0];
        // Remove unsafe elements from the file name 
        const updatedFileName = removeUnsafeElementsFromFileName(file.name);
        // Create a new file with the updated name and same content
        const newFile = new File([file], updatedFileName, { type: file.type });
        setImageFile(newFile);
        const reader = new FileReader();
        reader.onload = () => {
            if (reader && reader.result) {
                setLocalImgPath(String(reader.result));
            }
        };
        reader.readAsDataURL(newFile);
    };

    const convertUrlToFile = async (url: string, fileName: string): Promise<File> => {
        const response = await fetch(url);
        const data = await response.blob();
        const metadata = {
            type: data.type
        };
        const file = new File([data], fileName, metadata);
        return file;
    };

    const fetchDocsDetails = async(docId: string) => {
        try{
            setDocsLoading(true);
            const docsDetail: DocWidgetData = await getDocWidgetData(docId);
            if(!docsDetail) {
                toast.warn('No docs found associated with this asset',{
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                })
                setDocsLoading(false);
                return;
            }
            setAllDocs([docsDetail]);
            setDocsLoading(false);
        } catch (error) {
            setDocsLoading(false);
            toast.error('Something went wrong, please try again', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            console.error('error fetching doc', error);
        }
    }

    const panes: {
        pane?: SemanticShorthandItem<TabPaneProps>;
        menuItem?: string;
        render?: (() => React.ReactNode) | undefined;
    }[] = [
            {
            menuItem: source === cookbook ? "Cost" : "Defaults",
                render: () => {
                    return <Requirements dispatch={reqDispatch} formValues={reqState} staffList={staffList} source={source} />;
                },
            },
            {
                menuItem: 'Dietary',
                render: () => {
                    return (
                        <DietaryPanes
                            checkboxItems={checkboxItems}
                            dispatch={dispatch}
                            loading={loading}
                        />
                    );
                },
            },
            {
                menuItem: source === cookbook ? 'Meal Restrictions' : 'Wellness',
                render: () => {
                    return (
                        source === cookbook ?
                            <ServiceMealRestrictions
                                serviceDieteryState={dieteryState}
                                dieteryDispatch={dieteryDispatch}
                            /> :
                            <ProfilePanes
                                checkboxItems={checkboxItems}
                                dispatch={dispatch}
                                loading={loading}
                            />
                    );
                },
            },
            {
                menuItem: 'Details',
                render: () => {
                    return <Details details={details} setDetails={setDetails} />;
                },
            },
            {
                menuItem: 'Tax',
                render: () => {
                    return <TaxTab taxRates={taxRates} setTaxRates={setTaxRates} />;
                },
            },
            {
                menuItem: 'Docs',
                render: () => {
                    return <Docs allDocs={allDocs} staffList={staffList} residentsList={residentsList} groupsList={groupsList} isDocLoading={docsLoading} docId={docId} setDocId={setDocId}/>; 
                },
            },
        ]; 

    return (
        <div>
            {source === templateService && <h3 style={{ textAlign: "center" }}>{(service && service.CorporateId) ? "Corporate-Service Edit Form" : "Universal-Service Edit Form"}</h3>}
            <Form loading={isLoading}>
                <Form.Group widths="equal">
                    <Form.Field
                        required
                        control={Input}
                        name="name"
                        label={source === cookbook ? "Menu Item" : "Service Name"}
                        placeholder={source === cookbook ? "Menu Item" : "Service Name"}
                        onChange={handleInputChange}
                        value={formValues.name}
                        maxLength={25}
                    />
                    {source !== templateService && <Form.Field
                        required
                        control={Select}
                        name="Asset"
                        label={source === cookbook ? "Restaurant" : "Location/Asset"}
                        options={sortByKey(defaultAssets.map((item) => ({
                            key: item._id,
                            text: item.AssetName, 
                            value: item._id,
                        })))}
                        placeholder={source === cookbook ? "Restaurant" : "Location/Asset"}
                        onChange={handleInputChange}
                        value={formValues.Asset}
                        search
                    />}
                    {(source !== "cookbook") && (source !== templateService) && <Form.Field
                        control={Select}
                        name="SecondaryAssets"
                        label="Secondary Assets"
                        options={sortByKey(defaultAssets.map((item) => {
                            const isPrimaryAsset = item._id === formValues.Asset || false;
                            return {
                                key: item._id,
                                text: item.AssetName,
                                value: item._id,
                                disabled: isPrimaryAsset,
                            };
                        }))}
                        placeholder="Secondary Assets"
                        onChange={handleInputChange}
                        value={formValues.SecondaryAssets}
                        multiple
                        clearable
                        search
                    />}
                    {source === cookbook && (
                        <Form.Field
                            required
                            control={Select}
                            name="mealTypes"
                            label="Meal Type"
                            options={mealTypes.map((item, index) => ({
                                key: index,
                                text: item,
                                value: item,
                            }))}
                            placeholder="Select Meal Type"
                            onChange={handleInputChange}
                            value={formValues.mealTypes}
                            multiple
                        />
                    )}
                    {source !== cookbook && <Form.Field
                        required
                        control={Select}
                        name="category"
                        label="Category"
                        options={categoriesDropdownOptions}
                        placeholder="Category"
                        onChange={handleInputChange}
                        value={formValues.category}
                        search
                    />}
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field
                        required
                        control={Input}
                        name="shortDescription"
                        label="Short Description"
                        placeholder="Short Description"
                        onChange={handleInputChange}
                        value={formValues.shortDescription}
                        maxLength={60}
                    />
                    <Form.Field
                        control={TextArea}
                        name="longDescription"
                        label="Long Description"
                        placeholder="Long Description"
                        onChange={handleInputChange}
                        value={formValues.longDescription}
                    />
                    {source !== cookbook && <Form.Field
                        required={true}
                        control={Select}
                        name="Department"
                        label="Department"
                        options={sortByKey(departments.map((department) => ({
                            key: department._id,
                            text: department.Name,
                            value: department._id,
                        })))}
                        placeholder="Department"
                        onChange={handleInputChange}
                        value={formValues.Department}
                        search
                    />}
                    <Form.Field
                        control={Input}
                        name="url"
                        label={source === cookbook ? "Link to info" : "URL"}
                        placeholder={source === cookbook ? "Link to info" : "URL"}
                        onChange={handleInputChange}
                        value={formValues.url}
                    />
                </Form.Group>
                {
                    imageUrl && !imageFile ? (
                        <img
                            style={{ maxWidth: '100px', maxHeight: '100px' }}
                            className={styles.image}
                            ref={previewImgRef}
                            src={imageUrl}
                        />
                    ) :
                        imageFile && (
                            <label htmlFor="imageInput" style={{ cursor: "pointer" }}>
                                <img src={URL.createObjectURL(imageFile)} style={{ maxWidth: '100px', maxHeight: '100px' }} />
                            </label>
                        )
                }
                <Form.Group style={{ display: "flex", alignItems: "center" }} >
                    <Modal
                        onClose={() => {
                            setOpen(false);
                            setLocalImgPath(null);
                        }}
                        onOpen={() => setOpen(true)}
                        open={open}
                        trigger={
                            <Button style={{ marginLeft: "5px" }} type="button" size="tiny">
                                {imageUrl ? 'Change Image' : 'Upload Image'}
                            </Button>
                        }
                    >
                        <Modal.Header>Upload image</Modal.Header>
                        {(localImgPath || imageUrl) && (
                            <>
                                {
                                    fetchingImage ?
                                        <Dimmer active inverted>
                                            <Loader size='mini'>Loading</Loader>
                                        </Dimmer> :
                                        <div className={styles.container}>
                                            <img
                                                className={styles.image}
                                                ref={previewImgRef}
                                                src={localImgPath || imageUrl}
                                            />
                                        </div>
                                }
                            </>
                        )}
                        <FilePicker multiple={false} {...uploadState} onSelected={handleFileSelect} accept={['image/*']} />
                        <Modal.Actions>
                            <Button
                                type="button"
                                onClick={() => {
                                    setOpen(false);
                                    setLocalImgPath(null);
                                }}
                                color='blue'
                            >
                                Submit
                            </Button>
                            <Button
                                type="button"
                                onClick={() => {
                                    setOpen(false);
                                    setLocalImgPath(null);
                                    setImageFile(null);
                                }}
                            >
                                Cancel
                            </Button>
                        </Modal.Actions>
                    </Modal>
                    <Button type="button" disabled={templateId ? true : false} style={{ color: 'white', background: '#183466' }} size="tiny" onClick={() => setDocsModal(true)}>
                        Docs
                    </Button>
                    {docsModal && (
                        <ListDocsModal open={docsModal} setOpen={setDocsModal} docId={docId} setDocId={setDocId} source={formValues.name} />
                    )}
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Segment style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px', padding: '10px', borderRadius: '20px', height: '50px', marginLeft: '5px' }}>
                            {
                                (FacilityAdmin || PowerUser) && source !== cookbook && <Form.Field
                                    control={Checkbox} //for facility admin and power user
                                    name="active"
                                    label="Active"
                                    onChange={handleInputChange}
                                    checked={formValues.active}
                                />
                            }
                            {
                                source !== cookbook && <Form.Field>
                                    <Form.Field
                                        control={Checkbox}
                                        name="bookable"
                                        label="Bookable"
                                        onChange={handleInputChange}
                                        checked={formValues.bookable}
                                    />
                                </Form.Field>
                            }
                            <Form.Field>
                                <Checkbox
                                    name="waitingList"
                                    label="Waiting List"
                                    onChange={handleCheckboxChange}
                                    checked={reqState.waitingList}
                                />
                            </Form.Field>
                            {
                                source !== cookbook &&
                                <Form.Field>
                                    <Checkbox
                                        name="shareToCalendars"
                                        label="Share to Calendars"
                                        onChange={handleCheckboxChange}
                                        checked={reqState.shareToCalendars}
                                    />
                                </Form.Field>
                            }
                            <Form.Field>
                                <Checkbox
                                    name="displayInSignage"
                                    label="Display in Signage"
                                    onChange={handleCheckboxChange}
                                    checked={reqState.displayInSignage}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Checkbox control={Checkbox}
                                    name="autoNotification"
                                    label="Auto Notification"
                                    placeholder="AutoNotification"
                                    onChange={handleCheckboxChange}
                                    checked={reqState.autoNotification}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Checkbox control={Checkbox}
                                    name="autoAnnouncement"
                                    label="Auto Announcements"
                                    onChange={handleCheckboxChange}
                                    checked={reqState.autoAnnouncement}
                                />
                            </Form.Field>
                            {
                                reqState.autoAnnouncement &&
                                <Popup
                                    content="minutes"
                                    position="top center"
                                    trigger={<Form.Field
                                        style={{ width: '100px' }}
                                        control={Input}
                                        name="minutesBeforeAnnouncement"
                                        placeholder="Minutes"
                                        type='number'
                                        value={reqState.minutesBeforeAnnouncement}
                                        onChange={(event, { name, value }) => {
                                            const minutesBeforeAnnouncement = Number(value);
                                            if (minutesBeforeAnnouncement < 0) {
                                                toast.warn('Duration should be greater than 0',
                                                    {
                                                        position: 'bottom-center',
                                                        autoClose: 5000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                    });
                                            } else {                                                
                                                reqDispatch({
                                                    type: "HANDLE-INPUT-CHANGE",
                                                    payload: {
                                                        key: name,
                                                        value: minutesBeforeAnnouncement
                                                    }
                                                });
                                            }
                                        }}
                                    />
                                    }
                                />
                            }
                            {
                                (reqState.autoAnnouncement || reqState.autoNotification) &&
                                <Form.Field style={{ display: 'flex', alignItems: 'center' }}>
                                    <label style={{ fontWeight: 'lighter', marginTop: '7px' }}>Registered</label>
                                    <Checkbox
                                        style={{ marginLeft: '5px' }}
                                        name="alertTarget"
                                        toggle
                                        checked={reqState.alertTarget === 'all' ? true : false} // Check if it's 'All'
                                        onChange={handleCheckboxChange}
                                    />
                                    <label style={{ fontWeight: 'lighter', marginLeft: '5px', marginTop: '7px' }}>All</label>
                                </Form.Field>
                            }
                            {
                                FacilityAdmin && source !== cookbook && source != templateService && <>
                                    <Form.Field>
                                        <Form.Field
                                            control={Checkbox}
                                            name="universal"
                                            label="Share"
                                            onChange={handleInputChange}
                                            checked={formValues.universal || formValues.corporate}
                                        />
                                    </Form.Field>
                                    {
                                        (formValues.universal || formValues.corporate) &&
                                        <div style={{ backgroundColor: "#f9fafb", padding: '3px 5px', margin: "10px", borderRadius: '5px' }}>
                                            <Form.Field style={{ display: 'flex', alignItems: 'center' }}>
                                                <label style={{ fontWeight: 'lighter', marginTop: '5px' }}>Universal</label>
                                                <Form.Field
                                                    control={Checkbox}
                                                    style={{ marginLeft: '5px' }}
                                                    name="corporate"
                                                    toggle
                                                    checked={formValues.corporate}
                                                    onChange={handleInputChange}
                                                />
                                                <label style={{ fontWeight: 'lighter', marginLeft: '5px', marginTop: '5px' }}>Corporate </label>
                                            </Form.Field>
                                        </div>
                                    }
                                </>
                            }
                        </Segment>
                    </div>
                </Form.Group>
                <Divider style={{ borderTopWidth: '0.5px', borderTopColor: 'black' }}/>
                <ServiceAddOns addOns={addOns} setAddOns={setAddOns} source={source}/>
                <Grid style={{margin:'0 0 10px -9px'}}>
                    <Grid.Column width={8}>
                        <div className="ui buttons">
                            <Form.Button type="submit" onClick={handleFormSubmit} disabled={!isFormValid()} color='blue'>
                                Submit
                            </Form.Button>
                            <Form.Button style={{ marginLeft: '5px' }} type="button" onClick={() => history.push(source === cookbook ? '/admin/cookbook' : '/admin/services/list')}>
                                Cancel
                            </Form.Button>
                        </div>
                    </Grid.Column>
                </Grid>
                <Divider style={{ borderTopWidth: '0.5px', borderTopColor: 'black' }}/>
                <Tab panes={panes} style={{ marginLeft: '5px', marginBottom: '5px' }} />
            </Form>
        </div>
    );
};

export default withRouter(ServicesTypesForm);
