import React, { useEffect, useState } from 'react';
import { Button, Dimmer, Loader, Segment, Image, Dropdown, DropdownProps, Header, Input, Icon } from 'semantic-ui-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
    deleteCorporateService,
    deleteUniversalService,
    fetchPaginatedCorporateTemplates,
    fetchPaginatedUniversalTemplates,
    fetchUniversalServiceTypesImage,
    getServiceCategories,
} from '../../services/service';
import { useSelector } from 'react-redux';
import { AuthState } from '../../types';
import LoadMore from '../LoadMore';
import { toast } from 'react-toastify';
import { Service } from '../../types/Service';
import { sortByKey } from '../../util/sortData';
import ConfirmationModal from '../DeleteConfirmationModal';
import moment from 'moment-timezone';
import { isFacilityAdmin } from '../../services/Permissions';

interface PropsType extends RouteComponentProps {
    source: "Universal" | "Corporate";
    setTabLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}
const ServiceTemplateList = ({ history, source, setTabLoading }: PropsType) => {
    const [serviceLists, setServiceLists] = useState<Service[]>([]);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [selectedService, setSelectedService] = useState<Service | null>(null);
    const profile = useSelector(({ authReducer }: { authReducer: AuthState }) => {
        return authReducer.profile;
    });
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [selectedServiceToDelete, setSelectedServiceToDelete] = useState<Service | null>(null);
    const [page, setPage] = useState<number>(1);
    const [hasMorePages, setHasMorePages] = useState<boolean>(false);
    const [serviceImages, setServiceImages] = useState<Record<string, string>>({});
    const [selectedCategory, setSelectedCategory] = useState<string>('');
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [pagelimit, setPageLimit] = useState<number>(12);
    const [categoryOptions, setCategoryOptions] = useState<string[]>([]);
    const [categoryLoader, setCategoryLoader] = useState<boolean>(false);
    const FacilityAdmin: boolean = isFacilityAdmin(profile);

    useEffect(() => {
        if (page === 1) {
            setServiceLists([]);    // Reset the service list when the page is 1
        }
        refreshSvcList(true);
    }, [page, selectedCategory, source]);

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            setCategoryLoader(true);
            const categories = await getServiceCategories({ sortAlphabetically: true });
            setCategoryOptions(categories);
        } catch (error) {
            toast.warn(error instanceof Error ? error.message : "Failed to get categories", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            setCategoryLoader(false);
        }
    };

    const categoriesDropdownOptions = categoryOptions && categoryOptions.length > 0 && categoryOptions.map((category) => {
        return {
            key: category,
            text: category,
            value: category
        };
    }
    ) || [];

    const handleSync = (template: Service) => {
        history.push(
            {
                pathname: '/admin/services/create',
                state: { templateId: template._id }
            }
        );
    };

    const handleUpdateService = (serviceId: string) => {
        history.push(`/admin/services/template/${serviceId}`);
    };

    const handleDeleteService = async (serviceId: string) => {
        try {
            if (serviceId) {
                setOpenModal(false);
                setIsFetching(true);
                setSelectedService(null);
                source === "Universal" ? await deleteUniversalService(serviceId) : await deleteCorporateService(serviceId);
                setServiceLists((prevServiceLists) => prevServiceLists.filter((service) => service._id !== serviceId));
                toast.success('Successfully Deleted Service', {
                    position: 'bottom-center',
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
        } catch (error) {
            toast.error(error instanceof Error ? error.message : 'Something went wrong, please try again', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            setIsFetching(false);
            setOpenModal(false);
        }
    };

    const fetchServiceImages = async (services) => {
        const servicesWithImages = services.filter((service) => service.image);
        const imagePromises = servicesWithImages.map(async (service) => {
            if (service.image) {
                const imageUrl = await fetchUniversalServiceTypesImage(
                    service._id,
                    service.image,
                );
                return { [service._id]: imageUrl };
            }
        });

        // Wait for all image promises to resolve
        const imageResults = await Promise.all(imagePromises);
        return Object.assign({}, ...imageResults);
    };

    const refreshSvcList = async (loadMore = false) => {
        try {
            if (!loadMore) {
                setServiceLists([]);
            }
            setTabLoading && setTabLoading(true);
            setIsFetching(true);
            // Include both searchQuery and selectedCategory when fetching data
            const filter = {};
            if (selectedCategory) {
                filter['category'] = selectedCategory;
            }

            if (searchQuery !== '') {
                filter["name"] = { $regex: searchQuery, $options: 'i' };
            }
            const services = source === "Universal" ? await fetchPaginatedUniversalTemplates(filter, page, pagelimit) : await fetchPaginatedCorporateTemplates(filter, page, pagelimit);
            // Fetch images and update the state
            const imagesMap = await fetchServiceImages(services.Result);
            setServiceImages((prevImages) => ({ ...prevImages, ...imagesMap }));

            if (searchQuery === '') {
                // If there's no search query, append to existing list and avoid duplicates
                setServiceLists((prevServiceLists) => {
                    if (page === 1) {
                        // If page is 1, just return the sorted services
                        const sortedServiceList = services.Result.sort((a, b) => a.name.localeCompare(b.name));
                        return sortedServiceList;
                    } else {
                        const newServiceList = services.Result.filter(
                            (service) => !prevServiceLists.some((prevService) => prevService._id === service._id)
                        );
                        const sortedServiceList = [...prevServiceLists, ...newServiceList].sort((a, b) =>
                            a.name.localeCompare(b.name)
                        );
                        return sortedServiceList;
                    }
                });
                setHasMorePages(!(services.Result.length < pagelimit));
            } else {
                // If there's a search query, update the state with sorted search results
                const sortedServiceList = services.Result.sort((a, b) => a.name.localeCompare(b.name));
                setServiceLists(sortedServiceList);
                setHasMorePages(false);
            }
        } catch (error) {
            toast.warn(error instanceof Error ? error.message : 'Failed to fetch data', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            setIsFetching(false);
            setTabLoading && setTabLoading(false);
        }
    };

    const handleCategoryChange = (e: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
        const { value = "" } = data;
        setSelectedCategory(value as string);
        setSearchQuery(''); // Reset search query when the category changes
        if (value === "all") {
            setSelectedCategory("");
        }
        setPage(1);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
        if (event.target.value === '') {
            setPageLimit(12);
        }
    };

    const handleSearchClick = () => {
        // Trigger search when the search icon is clicked
        if (searchQuery === '') {
            setPageLimit(12);
        } else {
            setPageLimit(100);
        }
        setPage(1);
        refreshSvcList();
    };


    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Input
                    action={{ icon: 'search', onClick: handleSearchClick }}
                    placeholder={source === "Universal" ? 'Search univesal service' : "Search corporate service"}
                    value={searchQuery}
                    onChange={handleSearchChange}
                    style={{ width: '250px' }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSearchClick();
                        }
                    }}
                />
                <Dropdown
                    style={{ marginLeft: '10px', marginRight: '5px', width: '250px' }}
                    placeholder='Category'
                    search
                    selection
                    options={[
                        { value: 'all', text: source === "Universal" ? 'All Universal Services' : "All Corporate Services", key: "all" },
                        ...sortByKey(categoriesDropdownOptions)
                    ]}
                    loading={categoryLoader}
                    onChange={handleCategoryChange}
                    value={selectedCategory == '' ? 'all' : selectedCategory}
                />
            </div>
            <Dimmer active={isFetching} inverted>
                <Loader active={isFetching} size='small'>
                    Loading
                </Loader>
            </Dimmer>
            <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}>
                {serviceLists.map((service) => (
                    <div
                        key={service._id}
                        style={{
                            flex: `0 0 400px`,
                            margin: '5px',
                        }}
                    >
                        <Segment style={{
                            background: selectedService && selectedService._id === service._id ? '#e1e1e1' : 'white',
                            marginBottom: '10px',
                            transform: 'scale(1)',
                            minHeight: '200px',
                        }}>

                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <h3 style={{ textTransform: 'capitalize' }}>{service.name}</h3>
                                <div style={{ visibility: (profile && service.CreatedByFacility === profile.Facility && FacilityAdmin) ? 'visible' : 'hidden' }}>
                                    <Icon
                                        style={{ cursor: 'pointer', }}
                                        name="edit outline"
                                        size='large'
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            handleUpdateService(service._id || '');
                                        }}
                                    />
                                    <Icon
                                        style={{ cursor: 'pointer' }}
                                        name="trash"
                                        size='large'
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setOpenModal(true);
                                            setSelectedServiceToDelete(service);
                                        }}
                                    />
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    'marginTop': '10px'
                                }}
                            >
                                <div style={{ flex: '1' }}>
                                    <p style={{ textTransform: 'capitalize', maxHeight: '50px', overflow: 'hidden' }}>{service.shortDescription}</p>
                                    <p style={{ textTransform: 'capitalize' }}>{service.category}</p>
                                    <p style={{ textTransform: 'capitalize' }}>Last Updated on : {moment(service.LastUpdated || service.DateAdded).format("MM-DD-YYYY")}</p>
                                    <Button
                                        size='tiny'
                                        color='facebook'
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            handleSync(service);
                                        }}
                                    >
                                        Sync to this Community
                                    </Button>
                                </div>
                                {service.image && service.image !== '' && (
                                    <Image src={service.image !== '' && serviceImages[service._id]} size='small' style={{ 'height': '100px' }} floated='right' />
                                )}
                            </div>
                        </Segment>
                    </div>
                ))}
            </div>
            {serviceLists && serviceLists.length === 0 &&
                <div style={{ justifyContent: 'center', width: '100%', height: '90vh', display: 'flex', paddingTop: '55px' }}>
                    <Header as='h3' disabled>{source === "Universal" ? "No Universal Service Available" : "No Corporate Service Available"}</Header>
                </div>
            }
            <ConfirmationModal open={openModal} onClose={() => setOpenModal(false)} onConfirm={() => {
                selectedServiceToDelete && handleDeleteService(selectedServiceToDelete._id || '');
            }} messageToDisplay={source === "Universal" ? "You are about to permanently delete the Universal Service Service. This action cannot be undone. Please confirm that you wish to proceed with this deletion." : "You are about to permanently delete the Corporate Service. This action cannot be undone. Please confirm that you wish to proceed with this deletion."} />
            <LoadMore next={() => setPage((prev) => prev + 1)} isLoading={isFetching} hasMore={hasMorePages} />
        </>
    );
};

export default withRouter(ServiceTemplateList);