import React, { useEffect, useState } from 'react';
import { Dimmer, Loader, Pagination, Table, TableHeaderCell } from 'semantic-ui-react';
import { CallHistory } from '../../types/CallHistory';
import { sendToast, getLocaleDateTimewString } from '../../util';
import { ListPaginatedResidentCallHistory } from '../../services/CallHistory';

interface CallHistoryTableProps {
	residentId: string;
}

const CallHistoryTable = ({ residentId }: CallHistoryTableProps) => {
	const [callHistory, setCallHistory] = useState<CallHistory[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [pageNo, setPageNo] = useState<number>(1);
	const [totalPages, setTotalPages] = useState<number>(1);
	const pageSize = 10;

	useEffect(() => {
		fetchCallHistoryOfResident();
	}, [residentId, pageNo]);

	const fetchCallHistoryOfResident = async () => {
		try {
			setLoading(true);
			// fetch call history of resident
			const Filter = { residentId: String(residentId) };
			const { Result: callHistory, TotRecords } = await ListPaginatedResidentCallHistory(
				Filter,
				pageNo,
				pageSize
			);
			setCallHistory(callHistory);
			setTotalPages(Math.ceil(TotRecords as number / pageSize));

		} catch (e) {
			console.log(e);
			sendToast('error', 'Failed to fetch call history of resident');
		} finally {
			setLoading(false);
		}
	};

	const handleDisplayStaff = (call: CallHistory) => {
		if (call.isReceiverStaff) {
			return call.reciever;
		}
		else if (call.isCallerStaff) {
			return call.caller;
		}
		else {
			return "-";
		}
	};

	return (
		<div>
			<Dimmer active={loading} inverted>
				<Loader size="small">Loading</Loader>
			</Dimmer>
			<Table basic='very' >
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>Direction</Table.HeaderCell>
						<Table.HeaderCell>Day/Time</Table.HeaderCell>
						<Table.HeaderCell>To</Table.HeaderCell>
						<Table.HeaderCell>Length</Table.HeaderCell>
						<Table.HeaderCell>Staff</Table.HeaderCell>
						<Table.HeaderCell>Notes</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{callHistory && callHistory.length > 0 ?
						<>{callHistory.map((call, index) => {
							const direction = call.direction === 'inbound' ? 'Incoming' : 'Outgoing';
							const length = call.duration ? `${call.duration} seconds` : call.status;
							return (
								<Table.Row key={index}>
									<Table.Cell>{direction}</Table.Cell>
									<Table.Cell>{getLocaleDateTimewString(call.startTimestamp)}</Table.Cell>
									<Table.Cell>{call.reciever}</Table.Cell>
									<Table.Cell>{length}</Table.Cell>
									<Table.Cell>{handleDisplayStaff(call)}</Table.Cell>
									<Table.Cell>{call.notes}</Table.Cell>
								</Table.Row>
							);
						})}
							{
								(totalPages > 1) && <Table.Footer>
									<Table.Row>
										<TableHeaderCell colSpan='4' >
											<div style={{ display: "flex", justifyContent: "center", background: "red" }}>
												<Pagination
													boundaryRange={0}
													defaultActivePage={1}
													ellipsisItem={null}
													firstItem={null}
													lastItem={null}
													siblingRange={1}
													onPageChange={(event, data) => setPageNo(data.activePage as number)}
													totalPages={totalPages}
												/>
											</div>
										</TableHeaderCell>
									</Table.Row>
								</Table.Footer>
							}
						</> : (
							<Table.Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
								<Table.Cell colSpan="16" style={{ textAlign: "center" }}>
									<p>No Call History found for the resident</p>
								</Table.Cell>
							</Table.Row>
						)
					}
				</Table.Body>
			</Table>
		</div>
	);
};

export default CallHistoryTable;
