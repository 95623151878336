import React, { useRef, useState, useEffect, RefObject } from 'react';
import Calendar from '@fullcalendar/react';
import { Draggable } from '@fullcalendar/interaction';
import DatePicker from 'react-datepicker';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Button, Dimmer, Grid, Loader, Popup, Modal } from 'semantic-ui-react';
import ServicesAccordion from './ServicesAccordion';
import { ServicesType } from '../../types/ServicesTypes';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { AuthState } from '../../types';
import { addServiceInstance, fetchAllActivityAndServiceInstance, fetchAllMenuAndServiceInstance, fetchServiceInstance, updateServiceInstance, uploadServiceInstanceImage, upsertSignage } from '../../services/ServiceInstances';
import { Activities } from '../../services/DailyActivities';
import { toast } from 'react-toastify';
import { EventClickArg, EventDropArg } from '@fullcalendar/core';
import { fetchServiceTypesImage } from '../../services/service';
import ServiceFilterPrintExportIcons from '../FilterPrintExportIcons/serviceTypes';
import "./style.less";
import { serviceSourceType } from '../../types/ServiceInstance';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import CarouselRestaurant from '../RestaurantCarousel';
import { prntyrLink } from '../../util/data';
import PerDayDetails from '../PerDayDetails';
import { mealTypes, sendToast } from '../../util';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { Restaurant } from '../../types/Dining';
import { listAllRestaurant } from '../../services/Assets';
import { GetSlotTime } from '../../util/menu';
import { updateActivity } from '../../services/DailyActivities';
import ServiceInstanceCalendarEventContent from './ServiceInstanceCalendarEventContent';

const TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
interface PropsType extends RouteComponentProps {
  selectedCalendarType: string[];
  source: serviceSourceType;
  handleRedirect?: (id: string, calendarDate?: Date) => void;
  showSvcInstanceModal?: (formattedStartDate?: string, formattedEndDate?: string, menuCategory?: string) => void;
  showCreateForm?: () => void;
  reloadServiceAccordianFlag?: boolean;
  reloadSvcInstanceData?: boolean;
  setShowRecurringEditConfirmModal?: (id) => void;
  setSelectedActivity?: (id) => void;
  handlecalendarDateOfDailytab?: (date: Date) => void;
}

const ServiceInstances = (props: PropsType) => {
  const { history } = props;
  const queryString = history.location.search; // Get the query string, e.g., "?tab=daily&date=2024-06-04"
  const urlParams = new URLSearchParams(queryString); // Create a URLSearchParams object
  const dateString = urlParams.get('date'); // Get the value of the 'date' parameter
  const initialDate = dateString ? moment(dateString).toDate() : moment(new Date()).toDate();  
  const [droppedService, setDroppedService] = useState<ServicesType | null>(null);
  const [calendarLoading, setCalendarLoading] = useState<boolean>(false);
  const [intentList, setIntentList] = useState<ServicesType[]>([]);
  const containerEl = useRef<HTMLDivElement>(null);
  const calendarEl: RefObject<Calendar> = useRef<Calendar | null>(null);
  const [calendarDate, setCalendarDate] = useState<Date>(initialDate);
  const [instanceData, setInstanceData] = useState<Partial<ServicesType>>({
    name: '',
    longDescription: '',
    shortDescription: '',
    Asset: '',
    calendarType: props.selectedCalendarType,
    menuCategory: '',
  });
  const [filter, setFilter] = useState<string[]>([]);
  const [printBtnLoader, setPrintBtnLoader] = useState<boolean>(false);
  const [selectedRestaurant, setSelectedRestaurant] = useState<Restaurant | null>(null);
  const [showEventDropConfirmModal, setShowEventDropConfirmModal] = useState<boolean>(false);
  const [eventDropInfo, setEventDropInfo] = useState<EventDropArg | null>(null);
  const [showEventDropRecurrenceConfirmModal, setShowEventDropRecurrenceConfirmModal] = useState<boolean>(false);
  const profile = useSelector(({ authReducer }: { authReducer: AuthState; }) => authReducer.profile);
  
  useEffect(() => {
    if (props.source === "DailyActivities" && containerEl.current) {
      new Draggable(containerEl.current, {
        itemSelector: '.drag-able-event',
        eventData: function (eventEl) {
          const service = JSON.parse(eventEl.getAttribute('data-service') || "");
          setDroppedService(service);
          return {
            title: eventEl.innerText,
            id: eventEl.getAttribute('data-service'),
          };
        },
      });
    }
  }, [profile]);

  useEffect(() => {
    fetchCalendarData();
    setTimeout(() => { // The reason for using a timeout is to ensure this occurs after the component has rendered. Otherwise, it will lead to an error.
      calendarEl.current && calendarEl.current.getApi().gotoDate(calendarDate); // This sets the date of the calendar to the date selected by the user.
    }, 0);
  }, [calendarDate, filter, props.selectedCalendarType, props.reloadSvcInstanceData, selectedRestaurant]);

  const fetchCalendarData = async () => {
    try {
      setCalendarLoading(true);
      const formattedDate = moment(calendarDate).format('YYYY-MM-DD');
      const startDate = formattedDate + 'T00:00:00'; // YYYY-MM-DDTHH:mm:ss
      const endDate = formattedDate + 'T23:59:59'; // YYYY-MM-DDTHH:mm:ss
      let categoryFilter = filter;
      let intentListData;
      const calendarType = props.selectedCalendarType.length == 0 ? undefined : props.selectedCalendarType;
      if (props.source === 'Menu') {
        const menuFilter = { startDateTimeString: startDate, endDateTimeString: endDate, Facility: profile && profile.Facility || "", calendarType };
        if (selectedRestaurant && selectedRestaurant._id) menuFilter["Asset"] = String(selectedRestaurant._id);
        intentListData = await fetchAllMenuAndServiceInstance(menuFilter);
      } else if (props.source === 'DailyActivities') {
        intentListData = await fetchAllActivityAndServiceInstance({ startDateTimeString: startDate, endDateTimeString: endDate, Facility: profile && profile.Facility || "", calendarType, categories: categoryFilter });
      }
      setIntentList(intentListData);
    } catch (error) {
      console.error(error);
      toast.warn(error instanceof Error ? error.message : "Failed to fetch calendar data", {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    } finally {
      setCalendarLoading(false);
    }
  };

  const handleDrop = async (info: any) => {
    if (!droppedService) return;
    const droppedDate = info.date;
    const formattedStartDate = moment(droppedDate).format(TIME_FORMAT);
    const formattedEndDate = moment(droppedDate).add(1, 'hour').format(TIME_FORMAT);
    const modifiedService = {
      ...droppedService,
      originalServiceId: droppedService._id,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      calendarType: props.selectedCalendarType,
    };
    ['_id', 'wellness', 'dietary', 'LastUpdated', '_version', 'UpdatedBy'].forEach((field) => {
      delete modifiedService[field];
    });
    try {
      setCalendarLoading(true);
      const svcInstance = await addServiceInstance(modifiedService);
      if (modifiedService.image && modifiedService.Facility) {
        const facility = profile && profile.Facility || modifiedService.Facility;
        const link = await fetchServiceTypesImage(modifiedService.originalServiceId, modifiedService.image, facility);
        const response = await fetch(link);
        let signedUrl = '';
        const blob = await response.blob();
        const supportedImageTypes = ['image/png', 'image/jpg', 'image/jpeg'];
        if (!supportedImageTypes.includes(blob.type)) {
          toast.warn(
            "Only PNG and JPG files are allowed, Service image not added to service instance",
            {
              position: 'bottom-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            }
          );
        } else {
          const filename = modifiedService.image as string;
          const file = new File([blob], filename, { type: blob.type });
          signedUrl = await uploadServiceInstanceImage(file, svcInstance._id, profile && profile.Facility || "");
        }
        const serviceId = svcInstance._id;
        if (modifiedService.defaults && modifiedService.defaults.displayInSignage) {
          // Set endDate to be less than 9 PM
          // YYYY-MM-DDTHH:mm:ss
          const endOfDay = moment(modifiedService.endDate).hour(21).minute(0).seconds(0).format("YYYY-MM-DDTHH:mm:ss"); // Set to 9 PM in UT
          if (modifiedService.startDate && moment(modifiedService.startDate).isSameOrAfter(endOfDay)) {
            toast.warn(`Signage won't be created for selected time range`, {
              position: 'bottom-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });

          } else {
            await upsertSignage(serviceId, signedUrl);
          }
        }
      }
      await fetchCalendarData();
    } catch (error) {
      console.error("Error in handle drop", error);
      toast.warn(error instanceof Error ? error.message : "Failed to add data", {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    } finally {
      setCalendarLoading(false);
    }
  };

  const handleEventDrop = (info: EventDropArg) => {
    setEventDropInfo(info);
    setShowEventDropConfirmModal(true);
  }

    const handleEventDropConfirm = () => {
        const { event } = eventDropInfo as EventDropArg;
        // handle update event
        const selectedId = event._def.publicId;
        const selectedItem = intentList.find(
            (event) => event._id === selectedId,
        );
        if (selectedItem && selectedItem["recurrence"]) {
            // handle recurring event - confirm update current event or this and following events
            setShowEventDropRecurrenceConfirmModal(true);
        } else {
            handleRescheduleEvent();
        }
        // close confirmation modal
        setShowEventDropConfirmModal(false);
    };

    const handleRescheduleEvent = async ({
        updateRecurringEvent = false
    } = {}) => {
        try {
            // close confirmation modal
            setShowEventDropRecurrenceConfirmModal(false);
            // set loading
            setCalendarLoading(true);
            if (!eventDropInfo) {
                sendToast("warn", "Failed to update event, please try again");
                return;
            }
            const { event } = eventDropInfo as EventDropArg;
            // handle update event
            const selectedId = event._def.publicId;
            const selectedItem = intentList.find(
                (event) => event._id === selectedId,
            );
            const isSvcInstance = selectedItem && (selectedItem["svcMenuInstance"] || selectedItem["svcActivityInstance"]) || false;
            if (isSvcInstance) {
                // update startDate and endDate of service instance
                const startDate = moment(event.start).format(TIME_FORMAT);
                const endDate = moment(event.end).format(TIME_FORMAT);
                const currentServiceInstance = await fetchServiceInstance({ Filter: { _id: selectedId } });
                if (!currentServiceInstance) {
                    sendToast("warn", "Failed to update event");
                    return;
                }
                const updatedEvent = {
                    ...currentServiceInstance,
                    startDate,
                    endDate,
                };
                if (updateRecurringEvent) {
                    updatedEvent.thisAndFollowingDate = updatedEvent.startDate;
                    // generate a new recurrence string with current event date
                    const currentRecurrence = updatedEvent && updatedEvent["recurrence"]; // "DTSTART=20240901\nRRULE:FREQ=DAILY;INTERVAL=1;UNTIL=20240905"
                    const eventDate = moment(event.start).format('YYYYMMDD');
                    const newRecurrence = currentRecurrence.replace(/DTSTART=\d+/g, `DTSTART=${eventDate}`);
                    // update the current recurrence string to end at event start date
                    const dateBeforeEvent = moment(event.start).subtract(1, 'day').format('YYYYMMDD');
                    const updatedRecurrence = currentRecurrence.replace(/UNTIL=\d+/g, `UNTIL=${dateBeforeEvent}`);
                    updatedEvent.recurrence = updatedRecurrence;
                    // set start and end date to be the source date
                    const reccDtStart = currentRecurrence.split('\n')[0].split('=')[1];
                    const sourceDate = moment(reccDtStart, 'YYYYMMDD').format('YYYY-MM-DD');
                    const startDate = moment(sourceDate).set({
                      hour: moment(event.start).format('HH'),
                      minute: moment(event.start).format('mm'),
                      second: moment(event.start).format('ss'),
                    }).format(TIME_FORMAT);
                    const endDate = moment(sourceDate).set({
                      hour: moment(event.end).format('HH'),
                      minute: moment(event.end).format('mm'),
                      second: moment(event.end).format('ss'),
                    }).format(TIME_FORMAT);
                    updatedEvent.startDate = startDate;
                    updatedEvent.endDate = endDate;
                    updatedEvent.newRecurrence = newRecurrence;
                } else {
                    if (updatedEvent.recurrence) {
                        delete updatedEvent.recurrence;
                    }
                    if (updatedEvent.thisAndFollowingDate) {
                        delete updatedEvent.thisAndFollowingDate;
                    }
                    if (updatedEvent.newRecurrence) {
                        delete updatedEvent.newRecurrence;
                    }
                }
                const updatedServiceResponse = await updateServiceInstance(updatedEvent);
                const serviceId = String(selectedId);
                const signageUrl = ""; // this will be empty for update calls
                const imageKey = updatedEvent.image || '';
                const update = true
                await upsertSignage(
                  serviceId,
                  signageUrl,
                  update,
                  imageKey
                )
                if (updatedServiceResponse && updatedServiceResponse.warningMessage) {
                  const warnings = Object.keys(updatedServiceResponse.warningMessage);
                  warnings.forEach((warning) => {
                      if (warning) {
                          sendToast("warn", `${updatedServiceResponse.warningMessage[warning]}`);
                      }
                  });
                } else {
                  sendToast("success", "Event updated successfully");
                }
            } else {
                // update dateTimeString and timestamp
                const dateTimeString = moment(event.start).format(TIME_FORMAT);
                const timestamp = moment.tz(dateTimeString, profile && profile.FacilityTimeZone).valueOf();
                // get the current activity
                const response = await Activities({ _id: selectedId });
                const activity = response && response.Result && response.Result[0];
                if(!activity) {
                    sendToast("warn", "Failed to update event");
                    return;
                }
                const updatedEvent = {
                    ...activity,
                    dateTimeString,
                    timestamp
                };
                if (updateRecurringEvent) {
                    updatedEvent.modifyGroup = true;
                    const dtstart = updatedEvent.recurrence.split('\n')[0].split('=')[1];
                    const sourceDate = moment(dtstart, 'YYYYMMDD').format('YYYY-MM-DD');
                    const formattedDateTime = moment(sourceDate).set({
                        hour: moment(event.start).format('HH'),
                        minute: moment(event.start).format('mm'),
                        second: moment(event.start).format('ss'),
                    }).format(TIME_FORMAT);
                    updatedEvent.dateTimeString = formattedDateTime;
                    updatedEvent.timestamp = moment.tz(formattedDateTime, profile && profile.FacilityTimeZone).valueOf();
                } else {
                  if (updatedEvent.recurrence) {
                    delete updatedEvent.recurrence;
                  }
                  updatedEvent.modifyGroup = false;
                }
                const updatedActivityResponse = await updateActivity(updatedEvent);
                if (updatedActivityResponse) {
                    sendToast("success", "Event updated successfully");
                }
            }
            await fetchCalendarData();
        } catch (error) {
            sendToast("error", error instanceof Error ? error.message : "Failed to update event");
        } finally {
            setCalendarLoading(false);
        }
    }

  const handleEventDropCancel = () => {
    const { revert } = eventDropInfo as EventDropArg;
    revert();
    // close confirmation modal
    setShowEventDropConfirmModal(false);
  }


  const handleMenuDrop = async (droppedService: ServicesType, info: any, daySlot: string) => { // this function handles the drop of menu items on the calendar
    try {
      if (!(selectedRestaurant && selectedRestaurant._id)) {
        sendToast("warn", "Please select a restaurant to create a menu entry");
        return;
      }
      setCalendarLoading(true);
      const droppedStartDate = info.updatedStartDate;
      const formattedStartDate = moment(droppedStartDate).format(TIME_FORMAT);
      const droppedEndDate = info.updatedEndDate;
      const minutesSubtracted = (daySlot === "All Day" || daySlot === "Snack") ? 1 : 0;
      const formattedEndDate = moment(droppedEndDate).subtract(minutesSubtracted, 'minute').format(TIME_FORMAT);
      const modifiedService = {
        ...droppedService,
        originalServiceId: String(droppedService._id),
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        calendarType: props.selectedCalendarType,
        menuCategory: daySlot.toLowerCase(),
      };
      ['_id', 'wellness', 'dietary', 'LastUpdated', '_version', 'UpdatedBy'].forEach((field) => {
        delete modifiedService[field];
      });
      if (selectedRestaurant && selectedRestaurant._id) {
        modifiedService.Asset = selectedRestaurant && String(selectedRestaurant._id);
      }
      const allRestaurants = await listAllRestaurant();
      const svcInstanceRestaurant = allRestaurants.find((restaurant: Restaurant) => String(restaurant._id) === modifiedService.Asset);
      if (!svcInstanceRestaurant || svcInstanceRestaurant.AssetType !== "Restaurant") {
        throw new Error(`${droppedService.name} is not assigned with a restaurant, Please select a restaurant.`);
      } 
      const svcInstance = await addServiceInstance(modifiedService);
      if (modifiedService.image && modifiedService.Facility) {
        const facility = profile && profile.Facility || modifiedService.Facility;
        const link = await fetchServiceTypesImage(modifiedService.originalServiceId, modifiedService.image, facility);
        const response = await fetch(link);
        let signedUrl = '';
        const blob = await response.blob();
        const supportedImageTypes = ['image/png', 'image/jpg', 'image/jpeg'];
        if (!supportedImageTypes.includes(blob.type)) {
          toast.warn(
            "Only PNG and JPG files are allowed, Service image not added to service instance",
            {
              position: 'bottom-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            }
          );
        } else { // fetch service image and upload to service instance image
          const filename = modifiedService.image as string;
          const file = new File([blob], filename, { type: blob.type });
          signedUrl = await uploadServiceInstanceImage(file, String(svcInstance._id), profile && profile.Facility || "");
        }
        const serviceId = String(svcInstance._id);
        if (modifiedService.defaults && modifiedService.defaults.displayInSignage) {
          // Set endDate to be less than 9 PM
          // YYYY-MM-DDTHH:mm:ss
          const endOfDay = moment(modifiedService.endDate).hour(21).minute(0).seconds(0).format("YYYY-MM-DDTHH:mm:ss"); // Set to 9 PM in UT
          if (modifiedService.startDate && moment(modifiedService.startDate).isSameOrAfter(endOfDay)) {
            sendToast("warn", `Signage won't be created for selected time range`);

          } else {
            await upsertSignage(serviceId, signedUrl);
          }
        }
      }
      await fetchCalendarData();
    } catch (error) {
      sendToast("error", error instanceof Error ? error.message : "Failed to add data");
    } finally {
      setCalendarLoading(false);
    }
  };
  const handleMenuSelect = (id: string) => {
    let selectedId = String(id || "");
    const selectedItem = intentList.find((instance) => {
      return String(selectedId) === String(instance._id);
    });
    if (selectedItem && (selectedItem["svcMenuInstance"])) { // for service related menu items
      const selectedTab = history.location.search;
      const selectedDate = moment(calendarDate).format('YYYY-MM-DD');
      props.history.push(`/admin/menu/serviceinstance/${String(selectedItem._id)}`, { selectedTab, selectedDate });
    } else { // standlone menu items
      if (selectedItem && selectedItem["recurrence"]) {
        props.setShowRecurringEditConfirmModal && props.setShowRecurringEditConfirmModal(selectedId);
      } else {
        if (props.setSelectedActivity) props.setSelectedActivity(selectedId);
        props.handleRedirect && props.handleRedirect(selectedId, calendarDate);
      }
    }
  };
  const handleEventSelect = (data: EventClickArg) => {
    const selectedId = data.event._def.publicId;
    const selectedInstance = intentList.find((instance) => {
      return selectedId === instance._id;
    });
    if (selectedInstance && (selectedInstance["svcMenuInstance"] || selectedInstance["svcActivityInstance"])) {
      if (props.source === 'Menu') {
        const selectedTab = history.location.search;
        const selectedDate = moment(calendarDate).format('YYYY-MM-DD');
        props.history.push(`/admin/menu/serviceinstance/${selectedInstance._id}`, { selectedTab, selectedDate });
      } else if (props.source === 'DailyActivities') {
        const selectedTab = history.location.search;
        const selectedDate = moment(calendarDate).format('YYYY-MM-DD');
        props.history.push(`/admin/dailyactivities/serviceinstance/${selectedInstance._id}`, { selectedTab, selectedDate} );
      }
    } else {
      const selectedItem = intentList.find(
        (event) => event._id === selectedId,
      );
      if (selectedItem && selectedItem["recurrence"]) {
        props.setShowRecurringEditConfirmModal && props.setShowRecurringEditConfirmModal(selectedId);
      } else {
        if (props.setSelectedActivity) props.setSelectedActivity(selectedId);
        props.handleRedirect && props.handleRedirect(selectedId, calendarDate);
      }
    }
  };

  const formatExportData = () => {
    return intentList && intentList.map((instance: ServicesType) => {
      return {
        Name: instance.name && instance.name.replace(/\n/g, '') || '-',
        Description: instance.shortDescription || '-',
        Category: instance.menuCategory || instance.category || '-',
        Tags: instance.defaults && instance.defaults.tags || '-',
        StartDate: instance.startDate ? moment(instance.startDate).format('MM-DD-YYYY') : '-',
        StartTime: instance.startDate ? moment(instance.startDate).format('hh:mm A') : '-',
        EndDate: instance.endDate ? moment(instance.endDate).format('MM-DD-YYYY') : '-',
        EndTime: instance.endDate ? moment(instance.endDate).format('hh:mm A') : '-',
      };
    }) || [];
  };
  const handleSlotSelect = (mealCategory: string) => {
    const slotTime = GetSlotTime(mealCategory.toLowerCase());
    const updatedStartDate = new Date(calendarDate).setHours(slotTime.startHour, 0, 0, 0);
    const formattedStartDate = moment(updatedStartDate).format(TIME_FORMAT);
    const updatedEndDate = new Date(calendarDate).setHours(slotTime.endHour, 0, 0, 0);
    const minutesSubtracted = (mealCategory === "All Day" || mealCategory === "Snack") ? 1 : 0;
    const formattedEndDate = moment(updatedEndDate).subtract(minutesSubtracted, 'minute').format(TIME_FORMAT);
    setInstanceData({
      ...instanceData,
      menuCategory: mealCategory,
      startDate: formattedStartDate,
      endDate: formattedEndDate
    });
    if (props.showSvcInstanceModal) {
      props.showSvcInstanceModal(formattedStartDate, formattedEndDate, mealCategory);
    }
  };

  const handleDateSelect = (info) => {
    let formattedStartDate: string;
    let formattedEndDate: string;
    if (info.date) {
      const selectedDate = info.date;
      formattedStartDate = moment(selectedDate).format(TIME_FORMAT);
      formattedEndDate = moment(selectedDate).add(1, 'hour').format(TIME_FORMAT);
    } else {
      const minimumEndDate = moment(info.start).add(1, 'hour');
      formattedStartDate = moment(info.start).format(TIME_FORMAT);
      formattedEndDate = moment(info.end) >= minimumEndDate
        ? moment(info.end).format(TIME_FORMAT)
        : minimumEndDate.format(TIME_FORMAT);
    }
    setInstanceData({
      ...instanceData,
      startDate: formattedStartDate,
      endDate: formattedEndDate
    });
    if (props.showSvcInstanceModal) {
      props.showSvcInstanceModal(formattedStartDate, formattedEndDate);
    }
  };

  const handlePrintClick = async () => {
    window.open(prntyrLink, '_blank');
    // Commented out the print functionality as it is now redirecting to https://www.prntyr.com?speak2-user=true
    // setPrintBtnLoader(true);
    // const instanceList = intentList;
    // try {
    //   if (!instanceList || !instanceList.length) {
    //     toast.error('There are no events to print', {
    //       position: 'bottom-center',
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: false,
    //       draggable: true,
    //       progress: undefined,
    //     });
    //   } else {
    //     const formattedData = instanceList.map((instance) => {
    //       return {
    //         Subject: instance.name,
    //         StartDate: instance.startDate || "",
    //         StartTime: instance.endDate || "",
    //       };
    //     });
    //     await formatAndExportDataForPrintDateString(
    //       formattedData,
    //       'ServiceInstance-events',
    //     );//date-Time string
    //   }
    // } catch (error) {
    //   console.error(error);
    //   toast.error('Failed to print the calendar', {
    //     position: 'bottom-center',
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: false,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // } finally {
    //   setPrintBtnLoader(false);
    // }
  };

  const filterChangeHandler = (filterItems: string[]) => {
    setFilter(filterItems);
  };

  const filterCategoriesMenu = (time) => {
    if (time < 10 && time >= 8) {
      return "breakfast";
    } else if (time < 15 && time >= 12) {
      return "lunch";
    } else if (time < 20 && time >= 18) {
      return "dinner";
    } else {
      return "alternative";
    }
  };

  const getColor = (category?: string) => {
    switch (category) {
      case 'breakfast':
        return '#2987CD';
      case 'lunch':
        return '#69B546';
      case 'dinner':
        return '#EAC31F';
      case 'snack':
        return '#E98530';
      case 'alternative':
        return '#E98530';
      default:
        return '#2987CD';
    }
  };

  const slotLaneContent = (arg) => {
    if (props.source === 'Menu') {
      const startTime = new Date(arg.date).getHours();
      switch (filterCategoriesMenu(Number(startTime))) {
        case "breakfast": {
          return (
            <Popup
              key={arg.date.toString()}
              trigger={<div className="custom-time-slot" style={{ background: '#2987CD', opacity: 0.5 }}></div>}
              content="Breakfast slot"
              on="hover"
              position='top center'
              size='tiny'
            />
          );
        }
        case 'lunch': {
          return (
            <Popup
              key={arg.date.toString()}
              trigger={<div className="custom-time-slot" style={{ background: '#69B546', opacity: 0.5 }}></div>}
              content="Lunch slot"
              on="hover"
              position='top center'
              size='tiny'
            />
          );
        }
        case "dinner": {
          return (
            <Popup
              key={arg.date.toString()}
              trigger={<div className="custom-time-slot" style={{ background: '#EAC31F', opacity: 0.5 }}></div>}
              content="Dinner slot"
              on="hover"
              position='top center'
              size='tiny'
            />
          );
        }
        default: return null;
      }
    }
  };


  return (
    <>
      <DndProvider backend={HTML5Backend}>
        {
          props.source === 'Menu' && <CarouselRestaurant setSelectedRestaurant={setSelectedRestaurant} selectedRestaurant={selectedRestaurant && selectedRestaurant._id as string} />
        }
        <Grid style={{ paddingTop: "15px" }}>
          <Grid.Column width={10}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={5}>
                  {props.source !== "Menu" && <div className='service-icons'>
                    <ServiceFilterPrintExportIcons
                      printButtonLoading={printBtnLoader}
                      onPrintClick={handlePrintClick}
                      source={props.source}
                      exportData={formatExportData()}
                      filterChangeHandler={filterChangeHandler}
                      exportFileName="ServiceInstance-events"
                    />
                    <Popup
                      trigger={<Button className='searchBtnPlus' icon='plus' size='large' onClick={(e) => {
                        e.preventDefault();
                        if (props.showSvcInstanceModal) {
                          props.showSvcInstanceModal();
                        }
                      }} style={{ background: "none", padding: 0 }} loading={false} />}
                      content='Create a service instance'
                      size='tiny'
                      type="button"
                    />
                  </div>}
                  <div className='calendar-container customDatePickerWidth' style={{ marginTop: props.source === "Menu" ? "0px" : "20px" }}>
                    <DatePicker
                      open={true}
                      onChange={async (date: Date) => {
                        props.handlecalendarDateOfDailytab && props.handlecalendarDateOfDailytab(date);
                      setCalendarDate(date);
                      }}
                      style={{ border: '1px solid #183466' }}
                      inline
                      selected={calendarDate}
                      onMonthChange={async (date: Date) => {
                        props.handlecalendarDateOfDailytab && props.handlecalendarDateOfDailytab(date);
                      setCalendarDate(date);
                      }}
                    />
                  </div>
                </Grid.Column>
                <Grid.Column width={11}>
                  <div id="calendar-contain">
                    <Dimmer inverted active={calendarLoading}>
                      <Loader active={calendarLoading} />
                    </Dimmer>
                    {props.source === 'DailyActivities' ? (
                      <div>
                        <Calendar
                          events={intentList.map((intent) => ({
                            id: intent._id,
                            title: intent.name,
                            start: intent.startDate ? intent.startDate : "",
                            end: intent.endDate ? intent.endDate : "",
                            srcId: intent.srcId,
                            isSvcInstance: (intent["svcMenuInstance"] || intent["svcActivityInstance"]) || false,
                            backgroundColor: getColor(intent.menuCategory ? intent.menuCategory : intent.category),
                          }))}
                          allDaySlot={false}
                          ref={calendarEl}
                          plugins={[interactionPlugin, timeGridPlugin]}
                          headerToolbar={{ //left and right are specified to align title to center
                            left: '',
                            center: 'title',
                            right: '',  //timeGridDay,timeGridWeek,dayGridMonth
                          }}
                          customButtons={{
                            Today: {
                              text: 'Today',
                              click: () => {
                                const today = new Date();
                                calendarEl.current && calendarEl.current.getApi().gotoDate(today);
                              },
                            },
                          }}
                          initialView="timeGridDay"
                          editable={true} // allowing rescheduling of events
                          eventDurationEditable={false}    // Disable resizing of event duration
                          droppable={true}
                          eventDrop={(eventInfo) => handleEventDrop(eventInfo)}
                          drop={handleDrop}
                          eventClick={handleEventSelect}
                          slotLaneContent={slotLaneContent}
                          selectable={true} // Enable time slot selection
                          select={handleDateSelect} // Handle the selection of time slots
                          scrollTime="08:00:00"
                          height={"730px"}
                          eventContent={
                            (info) => (
                            <ServiceInstanceCalendarEventContent info={info} />
                            )
                          }
                        />
                        {eventDropInfo && (
                          <Modal
                            open={showEventDropConfirmModal}
                            onClose={() => setShowEventDropConfirmModal(false)}
                            size="small"
                          >
                            <Modal.Header>Event Reschedule Confirmation</Modal.Header>
                            <Modal.Content>
                            <p>
                              Do you want to reschedule the event <span style={{ color: '#2987CD' }}>{eventDropInfo.event._def.title}</span> from {moment(eventDropInfo.oldEvent.start).format('hh:mm A')} to {moment(eventDropInfo.event.start).format('hh:mm A')} on {moment(eventDropInfo.event.start).format('MM/DD/YYYY')}?
                            </p>
                            </Modal.Content>
                            <Modal.Actions>
                              <Button onClick={handleEventDropCancel} negative>
                                No
                              </Button>
                              <Button onClick={handleEventDropConfirm} positive>
                                Yes
                              </Button>
                            </Modal.Actions>
                          </Modal>
                        )}
                        <Modal
                            open={showEventDropRecurrenceConfirmModal}
                            onClose={() => setShowEventDropRecurrenceConfirmModal(false)}
                            size="small"
                          >
                          <Modal.Header>Event Reschedule Confirmation</Modal.Header>
                          <Modal.Content>
                            Do you want to reschedule just this event? or this and all following events?
                          </Modal.Content>
                          <Modal.Actions>
                            <Button onClick={() => handleRescheduleEvent()}>
                              Just this event
                            </Button>
                              {/* Conditionally render this and following events for only non src events */}
                              {eventDropInfo && ((!eventDropInfo.event._def.extendedProps.isSvcInstance) || (eventDropInfo.event._def.publicId && eventDropInfo.event._def.extendedProps.srcId && (eventDropInfo.event._def.publicId !== eventDropInfo.event._def.extendedProps.srcId))) && <Button onClick={() => handleRescheduleEvent({ updateRecurringEvent: true })}>
                              {/* This and following events */}
                              {(eventDropInfo && eventDropInfo.event._def.extendedProps.isSvcInstance) ? 'This and following events' : 'All events'}
                            </Button>}
                            <Button onClick={() => setShowEventDropRecurrenceConfirmModal(false)}>
                              Cancel
                            </Button>
                          </Modal.Actions>
                        </Modal>
                      </div>
                    ) :
                      <PerDayDetails
                        date={calendarDate}
                        name={selectedRestaurant && selectedRestaurant.AssetName || ""}
                        categoriesData={intentList}
                        perDayLists={mealTypes}
                        handleMenuSelect={handleMenuSelect}
                        handleSlotSelect={handleSlotSelect}
                        handleMenuDrop={handleMenuDrop}
                      />
                    }
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column width={6}>
            <div id="external-events" ref={containerEl}>
              <ServicesAccordion reloadFlag={props.reloadServiceAccordianFlag ? props.reloadServiceAccordianFlag : false} source={props.source} selectedRestaurant={selectedRestaurant && String(selectedRestaurant._id)} showSvcInstanceModal={props.showSvcInstanceModal} />
            </div>
          </Grid.Column>
        </Grid>
      </DndProvider>
    </>
  );
};

export default withRouter(ServiceInstances); 
