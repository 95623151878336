import React, { useEffect, useState } from 'react';
import { Dimmer, DropdownProps, Loader } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { CallHistory } from '../../types/CallHistory';
import { sendToast, getLocaleDateTimewString } from '../../util';
import { ListAllResidentCallHistory } from '../../services/CallHistory';
import CustomTable from '../CustomTable';
import { prntyrLink } from '../../util/data';
import { fetchAllActiveFacilityRegistrants } from '../../services/Registrants';
import { Registrant } from '../../types';

interface ReportCallLogsProps {
    startDate?: number;
    endDate?: number;
    facilityTimeZone?: string;
}

const ReportCallLogs = (props: ReportCallLogsProps) => {
    const [callHistory, setCallHistory] = useState<CallHistory[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedResidents, setSelectedResidents] = useState<string[]>([]);
    const [residents, setResidents] = useState<Registrant[]>([]);

    useEffect(() => {
        if (props.startDate && props.endDate) {
            fetchCallHistoryOfResident();
        }
    }, [props.startDate, props.endDate, selectedResidents]);

    useEffect(() => {
        try {
            (async () => {
                const excludeAlisImage = true;
                const includeRoomInfo = true;
                const residents = await fetchAllActiveFacilityRegistrants('', excludeAlisImage, includeRoomInfo);
                setResidents(residents);
            })();
        } catch (error) {
            sendToast('error', error instanceof Error ? error.message : 'Error fetching resident or staff data');
        }
    }, []);

    const fetchCallHistoryOfResident = async () => {
        try {
            setLoading(true);
            // fetch call history of resident
            let Filter = {};

            if (props.startDate && props.endDate) {
                Filter = {
                    ...Filter,
                    startTimestamp: { $gte: props.startDate, $lt: props.endDate }
                };
            }
            if (selectedResidents.length > 0) {
                Filter = {
                    ...Filter,
                    ...{ residentIds: selectedResidents },
                };
            }
            const callHistory = await ListAllResidentCallHistory(Filter);
            console.log('callHistory', callHistory);
            setCallHistory(callHistory);
        } catch (error) {
            sendToast('error', 'Failed to fetch call history of resident');
        } finally {
            setLoading(false);
        }
    };

    const handleDisplayStaff = (call: CallHistory) => {
        if (call.isReceiverStaff) {
            return call.reciever;
        }
        else if (call.isCallerStaff) {
            return call.caller;
        }
        else {
            return "-";
        }
    };

    const handlePrintClick = async () => {
        window.open(prntyrLink, '_blank');
    };

    const residentsDropdownChangeHandler = (_, data: DropdownProps) => {
        const { value } = data;
        setSelectedResidents((value as string[]) || []);
    };

    const tableHeaders = ['Direction', 'Day/Time', 'To', 'Length', 'Staff', 'Notes'];
    const tableKeys = ['direction', 'startTimestamp', 'receiver', 'duration', 'staff', 'notes'];
    const tableData = callHistory.map((call) => {
        const direction = call.direction === 'inbound' ? 'Incoming' : 'Outgoing';
        const duration = call.duration ? `${call.duration} seconds` : call.status;
        return {
            direction,
            startTimestamp: getLocaleDateTimewString(call.startTimestamp),
            receiver: call.reciever,
            duration,
            staff: handleDisplayStaff(call),
            notes: call.notes,
        };
    });

    const hideTableMessage = {
        header: 'No call logs found',
        content: 'No call logs found for the selected date range',
    };

    return (
        <div>
            <Dimmer active={loading} inverted>
                <Loader size="small">Loading</Loader>
            </Dimmer>
            <CustomTable
                facilityTimezone={props.facilityTimeZone || ''}
                formatString=""
                headers={tableHeaders}
                rowKeys={tableKeys}
                data={tableData}
                allowExport={true}
                allowPrint={true}
                formattedExportData={tableData}
                exportFileName="reports_call_history"
                pageNo={1}
                printHandler={handlePrintClick}
                printLoader={loading}
                selectResident={true}
                selectedResident={selectedResidents}
                handleSelectDropdown={residentsDropdownChangeHandler}
                residents={residents}
                overflowX='hidden'
                hideTableMessage={tableData.length === 0 ? hideTableMessage : undefined}
                refreshCurrPage={true}
                setHeight={'70vh'}
                filter={[]}
            />
        </div>
    );
};

export default ReportCallLogs;