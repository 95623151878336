import React from 'react'
import { Tab, TabProps, Button, Dimmer, Loader, Input, Grid, Item, Checkbox } from 'semantic-ui-react'
import UsersList from '../List'
import { fetchAdminUsers, fetchOneUser, fetchPowerUsers, fetchStaffUsers } from '../../../../services/Users'
import { User, UserProfile } from '../../../../types'
import './style.less'
import { RouteComponentProps, withRouter } from 'react-router'
import UserEdit from '../Edit/index'
import StaffTable from '../../../../components/StaffTable'
import { AppState } from '../../../../reducers'
import { connect } from 'react-redux'
import ResidentStaffCalendarComponent from '../../../../components/ResidentCalendar'
interface State {
    isFetching: boolean
    isCalendarFetching: boolean
    error: string | null
    users: User[]
    staff: User | null
    filter: 'staff' | 'admins' | 'power'
    search: string
    selectedId: string
    selectedUserId: string
    page_no: number
    TotRecords: number | undefined,
    isEdit: boolean
    showInactiveResidents?: boolean 
}

interface Props extends RouteComponentProps {
    profile: UserProfile | null;
}

class StaffHome extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isFetching: false,
            isCalendarFetching: false,
            error: null,
            users: [],
            staff: null,
            selectedId: props.match.params['id'] || '',
            selectedUserId: '',
            search: '',
            filter: 'staff' as 'staff' | 'admins' | 'power',
            page_no: 1,
            TotRecords: undefined,
            isEdit: false,
            showInactiveResidents: false
        };
    }

    panes = [
        {
            menuItem: 'Staff',
            render: () => (
                <Tab.Pane loading={this.state.isFetching}>
                    <UsersList users={this.state.users} error={this.state.error} selectedId={this.state.selectedId} />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Power Users',
            render: () => (
                <Tab.Pane loading={this.state.isFetching}>
                    <UsersList users={this.state.users} error={this.state.error} selectedId={this.state.selectedId} />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Facility Admins',
            render: () => (
                <Tab.Pane loading={this.state.isFetching}>
                    <UsersList users={this.state.users} error={this.state.error} selectedId={this.state.selectedId} />
                </Tab.Pane>
            ),
        }
    ]

    async fetcher(page_no = 1, search, showInactiveUsers): Promise<any> {
        switch (this.state.filter) {
            case 'staff':
                return fetchStaffUsers(page_no, search, 50, showInactiveUsers);
            case 'admins':
                return fetchAdminUsers(page_no, search, showInactiveUsers);
            case 'power':
                return fetchPowerUsers(page_no, search, showInactiveUsers);
        }
    }

    async fetchUsers(page_no = 1, reload = false) {
        this.setState({ isFetching: true, error: null })
        try {
            const { users, TotRecords } = await this.fetcher(page_no, this.state.search, this.state.showInactiveResidents);
            this.setState({
                isFetching: false,
                error: null,
                users: reload ? users : this.state.users.concat(users),
                page_no,
                TotRecords
            })
        } catch (e) {
            this.setState({
                isFetching: false,
                error: e.message,
            })
        }
    }

    async fetchStaff(id: string) {
        this.setState({ isCalendarFetching: true, error: null })
        try {
            const user = await fetchOneUser(id)
            this.setState({
                isCalendarFetching: false,
                error: null,
                staff: user,
                selectedUserId: id,
            })
        } catch (e) {
            this.setState({
                isCalendarFetching: false,
                error: e.message,
            })
        }
    }

    navigateToCreate() {
        this.props.history.push('/admin/users/create')
    }

    updateSearch(value) {
        this.setState({
            search: value,
        })
    }

    search() {
        this.setState(
            {
                users: [],
                error: null,
                isFetching: false,
                page_no: 1
            },
            () => {
                return this.fetchUsers()
            },
        )
    }

    handleChange(_: React.MouseEvent<HTMLDivElement, MouseEvent>, data: TabProps) {
        if (data.activeIndex === 0) {
            this.setState({ filter: 'staff', page_no: 1, users: [] }, this.fetchUsers)
        } else if (data.activeIndex === 1) {
            this.setState({ filter: 'power', page_no: 1, users: [] }, this.fetchUsers)
        } else {
            this.setState({ filter: 'admins', page_no: 1, users: [] }, this.fetchUsers)
        }
    }

    componentDidMount() {
        this.setState({
            page_no: 1
        });
        this.fetchUsers()
        if(this.state.selectedId)
        this.fetchStaff(this.state.selectedId)
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        const prevPropsId = prevProps.match.params['id'] || ''
        const currentPropsId = this.props.match.params['id'] || ''
        if (prevPropsId !== currentPropsId) {
            this.setState({
                selectedId: currentPropsId,
            },
                () => {
                    if (currentPropsId) {
                        this.fetchStaff(currentPropsId);
                    } else {
                        this.setState({
                            staff: null,
                        });
                    }
                })
        }
    }

    handleIsEdit(isSubmit: boolean = false): void {
        this.setState({
            isEdit: !this.state.isEdit
        }, () => {
            if (isSubmit) this.fetchUsers(this.state.page_no, true)
            if (this.isSysadmin()) {
                this.props.history.push('/admin/users/list');
            }
        })
    }

    isSysadmin() {
        return !(this.props.profile && this.props.profile.Facility)
    }

    render() {
        return (
            <div className="UsersHome">
                <Dimmer active={this.state.isFetching} inverted>
                    <Loader active={this.state.isFetching} />
                </Dimmer>
                <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                    <Button
                        content="Create New"
                        icon="add"
                        labelPosition="left"
                        primary
                        onClick={this.navigateToCreate.bind(this)}
                    />
                    <div className="regSearch">
                        <Input
                            className="searchText"
                            placeholder="Search staff"
                            type="text"
                            value={this.state.search}
                            onChange={(_, { value }) => this.updateSearch(value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    this.search()
                                }
                            }}
                        ></Input>
                        <Button className="searchBtn" icon="search" onClick={() => this.search()} />
                    </div>
                    <Checkbox
                        label={`Include inactive`}
                        checked={this.state.showInactiveResidents}
                        onChange={(_, { checked }) => {
                            this.setState({
                                showInactiveResidents: checked,
                                users: [],
                                error: null,
                                isFetching: false,
                                page_no: 1
                            }, () => {
                                this.fetchUsers()
                            })
                        }}
                    />
                </div>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={!this.isSysadmin() ? 4 : (this.state.selectedUserId && this.state.staff ? 8 : 16)}>
                            <Tab
                                menu={{ secondary: true, pointing: true }}
                                panes={this.panes}
                                onTabChange={this.handleChange.bind(this)}
                            />
                            {!this.state.isFetching && <Button basic fluid color='blue' content='Load more' style={{ marginTop: '1em' }} onClick={() => this.fetchUsers(this.state.page_no + 1)} disabled={!!(this.state.TotRecords && (this.state.TotRecords < 50))} />}
                        </Grid.Column>
                        {!this.isSysadmin() ? <Grid.Column width={12}>
                            {
                                this.state.selectedUserId && this.state.staff ?
                                    <>
                                        <Grid>
                                            <Grid.Row>
                                                <Dimmer active={this.state.isCalendarFetching} inverted>
                                                    <Loader active={this.state.isCalendarFetching} />
                                                </Dimmer>
                                                <Grid.Column width={this.state.isEdit ? 7 : 15}>
                                                    <ResidentStaffCalendarComponent userProfile={this.state.staff} isStaff={true} key={this.state.selectedUserId} isEdit={this.state.isEdit} handleIsEdit={() => this.handleIsEdit.bind(this)} />
                                                </Grid.Column>
                                                <Grid.Column width={this.state.isEdit ? 9 : 1}>
                                                    {this.state.isEdit ? <UserEdit profile={this.props.profile} id={this.state.selectedUserId} handleIsEdit={this.handleIsEdit.bind(this)} key={this.state.selectedUserId} /> :
                                                        <Item>
                                                            <Item.Image onClick={() => { this.handleIsEdit() }} avatar size="big" className="profile-pic user-icon" src={`${process.env.PUBLIC_URL}/avatar_placeholder.svg`} />
                                                        </Item>}
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </>
                                    :
                                    <Grid.Column width={16}>
                                        <Dimmer active={this.state.isCalendarFetching} inverted>
                                            <Loader active={this.state.isCalendarFetching} />
                                        </Dimmer>
                                        <StaffTable />
                                    </Grid.Column>
                            }
                        </Grid.Column> : <>
                            <Dimmer active={this.state.isCalendarFetching} inverted>
                                <Loader active={this.state.isCalendarFetching} />
                            </Dimmer>
                            {this.state.selectedUserId && this.state.staff ?
                                <Grid.Column width={8}>
                                    <UserEdit profile={this.props.profile} id={this.state.selectedUserId} handleIsEdit={this.handleIsEdit.bind(this)} key={this.state.selectedUserId} />
                                </Grid.Column> : <></>}
                        </>}
                    </Grid.Row>
                </Grid>
            </div>
        )
    }
}

function mapStateToProps({ authReducer }: AppState) {
    return {
        profile: authReducer.profile,
    }
};

export default connect(mapStateToProps)(withRouter(StaffHome))
