import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AuthState } from '../../types';
import { listQueueStatus } from '../../services/AlexaHospitalityQueueStatus';
import { Button, Modal } from 'semantic-ui-react';
import CustomTable from '../CustomTable';
import momentTz from 'moment-timezone';
import { sendToast } from '../../util';
import { getWifiPairingStatus } from '../../services/A4hEndpoints';
import { AssertWifiStatusTableItem, WifiAssertionInfo, AssertWifiHospitalityStatusItem } from '../../types/AlexaHospitalityQueueStatus';

const AssertWifiList = () => {
    const [wifiStatuses, setWifiStatuses] = useState<AssertWifiStatusTableItem[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [openDeviceModal, setOpenDeviceModal] = useState<boolean>(false);
    const [selectedAssertionInfo, setSelectedAssertionInfo] = useState<WifiAssertionInfo[]>([]);
    const [totalDataLength, setTotalDataLength] = useState<number>(0);
    const [pageNo, setPageNo] = useState<number>(1);
    const pageSize = 8;

    const profile = useSelector(({ authReducer }: { authReducer: AuthState; }) => {
        return authReducer.profile;
    });
    const assertionInfoHeaders = ['Room', 'Resident', 'Device', 'DSN', 'Status', 'Last Updated', 'Refresh Status'];
    const assertionInfoKeys = [
        'roomName',
        'residentName',
        'deviceFriendlyName',
        'dsn',
        'statusText',
        'lastUpdated',
        'refreshButton',
    ];
    const statusTableHeaders = ['Room', 'Resident', 'Created At', 'Details'];
    const statusTableKeys = ['roomName', 'residentName', 'createdAt', 'deviceModal'];

    useEffect(() => {
        fetchAssertWifiStatus();
    }, [pageNo]);

    const fetchAssertWifiStatus = async (selectedStatusItem: AssertWifiHospitalityStatusItem = {} as AssertWifiHospitalityStatusItem) => {
        try {
            setLoading(true);
            const filters = {
                EventType: 'AssertWifi',
                Facility: profile && profile.Facility,
            };
            const pageSort = {
                page_size: pageSize,
                page_no: pageNo,
                sort: [{ DateAdded: 'asc' }],
            };
            const response = await listQueueStatus({ filters, pageSort });
            if (response && response.Result && response.Result.length > 0) {
                // Normalize the data for table and render it in the UI
                if (!(profile && profile.FacilityTimeZone)) {
                    sendToast('error', 'Facility timezone is missing, which will cause the created date and time to be incorrect. Please contact support.');
                }
                const formattedWifiStatusData: AssertWifiStatusTableItem[] = response.Result.map((wifiStatus: AssertWifiHospitalityStatusItem) => {
                    if (wifiStatus.ApiResponse && wifiStatus.ApiResponse.length > 0) {
                        if (selectedStatusItem && selectedStatusItem._id === wifiStatus._id) {
                            assertionInfoRefreshStatusHandler(wifiStatus);
                        }
                        const deviceModal = (
                            <Button
                                size='mini'
                                color='blue'
                                onClick={() => assertionInfoRefreshStatusHandler(wifiStatus)}
                            >
                                View Device details
                            </Button>
                        );
                        return {
                            roomName: Array.from(new Set(wifiStatus.ApiResponse.map((wifiApiRes) => wifiApiRes && wifiApiRes.room && wifiApiRes.room.RoomName))).join(', ') || '-', // Removes duplicates using set
                            residentName: Array.from(new Set(wifiStatus.ApiResponse.map((wifiApiRes) => wifiApiRes && wifiApiRes.room && wifiApiRes.room.Resident))).join(', ') || '-', // Removes duplicates using set
                            createdAt: momentTz.tz(wifiStatus.DateAdded, profile && profile.FacilityTimeZone).format('MM/DD/YYYY hh:mm A'),
                            deviceModal,
                        };
                    } else {
                        return {
                            roomName: Array.from(new Set(wifiStatus.QueuePayload.rooms.map((payload) => payload.RoomName))).join(', ') || '-', // Removes duplicates using set
                            residentName: Array.from(new Set(wifiStatus.QueuePayload.rooms.map((payload) => payload.Resident))).join(', ') || '-', // Removes duplicates using set
                            createdAt: momentTz.tz(wifiStatus.DateAdded, profile && profile.FacilityTimeZone).format('MM/DD/YYYY hh:mm A'),
                            deviceModal: 'Missing info - Please verify that the device is in the correct room and is online.',
                        };
                    }
                }).filter(Boolean);
                setTotalDataLength(response.TotRecords);
                setWifiStatuses(formattedWifiStatusData);
            } else {
                setWifiStatuses([]);
                setSelectedAssertionInfo([]);
            }
        } catch (error) {
            sendToast('error', error instanceof Error ? error.message : 'Something went wrong in fetching wifi status');
        } finally {
            setLoading(false);
        }
    };
    const assertionInfoRefreshStatusHandler = (wifiStatus: AssertWifiHospitalityStatusItem) => {
        const assertionInfoWithDevices = wifiStatus.ApiResponse.map((wifiApiRes) => {
            const refreshButton = (
                <Button
                    basic
                    circular
                    size="mini"
                    loading={loading}
                    icon="refresh"
                    style={{ marginLeft: '5px' }}
                    disabled={loading || (wifiApiRes && wifiApiRes.wifiPairingStatus) === 'SUCCESS' || !(wifiApiRes && wifiApiRes.getEndpointRes && wifiApiRes.getEndpointRes.endpointId)}
                    onClick={() => (wifiApiRes && wifiApiRes.getEndpointRes && wifiApiRes.getEndpointRes.endpointId) && updateWifiPairingStatus({
                        endpointId: wifiApiRes.getEndpointRes.endpointId,
                        operationId: wifiApiRes.responseData.submittedOperationId,
                        statusId: wifiStatus._id,
                        selectedStatusItem: wifiStatus
                    })}
                />
            );
            return {
                refreshButton: refreshButton,
                residentName: wifiApiRes && wifiApiRes.room && wifiApiRes.room.Resident || '-',
                roomName: wifiApiRes && wifiApiRes.room && wifiApiRes.room.RoomName || '-',
                a4hRoomId: wifiApiRes && wifiApiRes.room && wifiApiRes.room.RoomId || '-',
                deviceEndpointId: wifiApiRes && wifiApiRes.getEndpointRes && wifiApiRes.getEndpointRes.endpointId || '-',
                deviceModel: wifiApiRes && wifiApiRes.getEndpointRes && wifiApiRes.getEndpointRes.deviceModel || '-',
                dsn: wifiApiRes && wifiApiRes.getEndpointRes && wifiApiRes.getEndpointRes.dsn || '-',
                deviceFriendlyName: wifiApiRes && wifiApiRes.getEndpointRes && wifiApiRes.getEndpointRes.deviceFriendlyName || '-',
                statusText: (wifiApiRes && wifiApiRes.wifiPairingStatus) || (wifiApiRes && wifiApiRes.statusText) || 'INITIATED',
                statusCode: wifiApiRes && wifiApiRes.status || '-',
                _id: wifiStatus._id,
                lastUpdated: momentTz.tz(wifiStatus.LastUpdated, profile && profile.FacilityTimeZone).format('MM/DD/YYYY hh:mm A'),
                operationId: wifiApiRes && wifiApiRes.responseData && wifiApiRes.responseData.submittedOperationId || '-',
            };
        }).filter(Boolean);
        setSelectedAssertionInfo(assertionInfoWithDevices);
        setOpenDeviceModal(true);
    };

    const updateWifiPairingStatus = async ({ endpointId, operationId, statusId, selectedStatusItem }) => {
        try {
            setLoading(true);
            if (!endpointId || !operationId || !statusId) {
                throw new Error('Invalid data to update wifi status');
            }
            await getWifiPairingStatus(endpointId, operationId, statusId);
            await fetchAssertWifiStatus(selectedStatusItem);
        } catch (error) {
            sendToast('error', error instanceof Error ? error.message : 'Something went wrong in updating wifi status');
        } finally {
            setLoading(false);
        }
    };
    return (
        <>
            <CustomTable
                headers={statusTableHeaders}
                data={wifiStatuses}
                loading={loading}
                rowKeys={statusTableKeys}
                facilityTimezone=""
                formatString=""
                overflowX="hidden"
                isServerPagination={true}
                pageNo={pageNo}
                setPageNo={setPageNo}
                itemsPerPage={pageSize}
                totalDataLength={totalDataLength}
            />
            <Modal
                open={openDeviceModal} // Set the state to open the modal
                size='large'
                onClose={() => {
                    // Close the modal
                    setOpenDeviceModal(false);
                    setSelectedAssertionInfo([]);
                }}
            >
                <div>
                    <CustomTable
                        headers={assertionInfoHeaders}
                        data={selectedAssertionInfo}
                        loading={loading}
                        rowKeys={assertionInfoKeys}
                        facilityTimezone=""
                        formatString=""
                        overflowX='hidden'
                    />
                </div>
            </Modal>
        </>
    );
};

export default AssertWifiList;