import React from 'react'
import { Item } from 'semantic-ui-react'
import UserItem from '../UserItem'
import { UserLike } from '../../types'

import './style.less'

interface Props {
    users: UserLike[]
    onClick?: (id: string) => any
    source?: 'registrants' | 'staff'
    onShowAlexaClick?: (id: string) => void
    selectedId?: string
    annoucementIconClickHandler?: (id: string | undefined) => void
    notificationIconClickHandler?: (id: string | undefined) => void
    requestIconClickHandler?: (id: string) => void
}

const UsersList: React.FC<Props> = (props: Props) => {
    const handleClick = (id: string) => {
        if (props.onClick) props.onClick(id)
    }

    const sortedUsers = [...props.users];
    if (props.selectedId) {
        // Sort the users so that the selected user is always on top.
        sortedUsers.sort((a, b) => {
            if (a._id === props.selectedId) return -1; // move a to a position before b
            if (b._id === props.selectedId) return 1;  // move b to a position before a
            return 0;  // maintain the current order of a and b
        });
    }

    const items = sortedUsers.map(user => {
        return (
            <UserItem
                key={user._id}
                id={user._id}
                name={user.FirstName + ' ' + user.LastName}
                avatarUrl={user.Image}
                details={user.Details}
                onClick={() => handleClick(user._id)}
                roomName={user.roomName}
                a4hRoomId={user.a4hRoomId}
                source={props.source}
                onShowAlexaClick={() => {
                    if (props.onShowAlexaClick) props.onShowAlexaClick(user._id)
                }}
                isItemSelected={props.selectedId === user._id ? true : false}
                annoucementIconClickHandler={props.annoucementIconClickHandler}
                notificationIconClickHandler={props.notificationIconClickHandler}
                requestIconClickHandler={props.requestIconClickHandler}
                residentPremise={user.onPremise}
                residentSocialOptOut={user.Private || false}
                disabled={user.disabled}
                isExternalResident={user.isExternalResident}
                isAlisResident={user.isAlisResident}
                externalIntegrationSource={user.externalIntegrationSource}
                secondaryResident={user.primaryResidentId ? true : false}
                CommunityLiaison={user.CommunityLiaison ? true : false}
                IsActive={user.IsActive ? user.IsActive : 0}
            />
        )
    })

    return (
        <Item.Group divided unstackable>
            {items}
        </Item.Group>
    )
}

export default UsersList
