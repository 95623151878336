import React, { useState, useEffect } from 'react'
import { Form, Dropdown, Message, Loader, Dimmer, Grid } from 'semantic-ui-react'
import { Promise } from 'bluebird'
import { enableSkill, disableSkill } from '../../../services/Skills'
import { fetchResidentRooms } from '../../../services/Facilities'
import { fetchAllActiveFacilities, fetchResidentRoomsForFacilityId } from '../../../services/Facilities'
import { sortByKey } from '../../../util/sortData';
import { deleteWebRTCToken } from '../../../services/WebRTC'
import ConfirmationModal from '../../../components/DeleteConfirmationModal'

const ManageSkills = (props) => {
    const [rooms, setRooms] = useState<{RoomId: string, Resident: string}[]>([])
    const [facilities, setFacilities] = useState<{Name: string, id: string}[]>([])
    const [options, setOptions] = useState<any>([])
    const [selectedOptions, setSelectedOptions] = useState<any>([])
    const [submitting, setSubmitting] = useState<boolean>(false)
    const [skillId, setSkillId] = useState<string>('')
    const [errMsg, setErrMsg] = useState('')
    const [warning, setWarning] = useState<any>('')
    const [successMsg, setSuccessMsg] = useState<string>('')
    const [fetching, setFetching] = useState(true)

    const formatAndSortRooms = (rooms) => {
        const formattedRooms = rooms.map(obj => {
            return {
                key: `${obj.RoomId}`,
                text: `${obj.Resident}`,
                value: `${obj.RoomId}`
            }
        })

        const sortedRooms = formattedRooms.sort((a,b)=>{
            const A = a.text.toUpperCase()
            const B = b.text.toUpperCase()

            if (A<B)
                return -1
            if (A>B)
                return 1
            return 0
        })

        return sortedRooms
    }

    const formatAndSortFacilities = (facilities) => {
        return facilities
            .map((f) => ({
                key: f.id,
                text: f.Name,
                value: f.id,
            }))
            .sort((a, b) => {
                const A = a.text.toUpperCase()
                const B = b.text.toUpperCase()

                if (A < B) return -1
                if (A > B) return 1
                return 0
            })
    }

    const fetchAndSetDropdownOptions = async () => {
        setFetching(true)
        if (props.managePropertiesSkills) {
            const facilities = (await fetchAllActiveFacilities()).map(f => ({Name: f.Name, id: f._id}))
            console.log('facs', facilities)
            setFacilities(facilities)
            const formattedFacilities = formatAndSortFacilities(facilities)

            if (formattedFacilities.length > 1) {
                const optsWithAllFacilities = [{key:"all", text:"All facilities", value: 'all'}].concat(formattedFacilities)
                setOptions(optsWithAllFacilities)
            } else {
                setOptions(formattedFacilities)
            }
        } else {
            const rooms = await fetchResidentRooms()
            setRooms(rooms)
            const formattedRooms = formatAndSortRooms(rooms)
            if (formattedRooms.length > 1) {
                const optsWithAllRooms = [{key:"all", text:"All apartments", value: 'all'}].concat(formattedRooms)
                setOptions(optsWithAllRooms)
            } else {
                setOptions(formattedRooms)
            }
        }
        setFetching(false)
    }

    const clearForm = () => {
        setSkillId('')
        setSelectedOptions([])
    }

    const clearFlags = () => {
        setSuccessMsg('')
        setWarning('')
        setErrMsg('')
    }

    const getA4hRoomIdsFromFacilityIds = async (facilityIds) => {
        return (await Promise.map(
            facilityIds,
            async (facilityId) => {
                return await fetchResidentRoomsForFacilityId(facilityId)
            },
            {
                concurrency: 5
            }
        ))
        .flat()
        .map(r => r.RoomId)
        .filter(x => x)
    }

    const getResRoomName = (id) => {
        const roomObject = options.find((e) => e.key === id)
        return roomObject && roomObject.text ? roomObject.text : id
    }

    const handleEnable = async (skillId='', ids=[]) => {
        clearFlags()
        if (!skillId || !ids.length) {
            setErrMsg('Form incomplete')
            return
        }
        setSubmitting(true)
        try {
            let a4hRoomIds: string[] = []
            if (props.managePropertiesSkills) {
                //ids -> facilityIds
                a4hRoomIds = await getA4hRoomIdsFromFacilityIds(ids)
            } else {
                a4hRoomIds = ids
            }
            const res = await enableSkill(skillId, a4hRoomIds)

            if (res.statusId) {
                setSuccessMsg('Skill assigment is in progress, Please check after some time.')
            }

            if (res.errors) {
                if (res.errors && !res.errors.length) {
                    setWarning("Failed to enable skill")
                }
                if (res.errors.length) {
                    const sortedErrorsWithRoom:{errorDescription: string, room: string}[] = res.errors.map(({errorDescription, a4hRoomId}) => ({errorDescription, room: getResRoomName(a4hRoomId)})).sort((a, b) => {
                        if(a.errorDescription < b.errorDescription) return -1
                        if(a.errorDescription > b.errorDescription) return 1
                        return 0
                    })
                    const final :{errorDescription: string, rooms: string[]}[] = []
                    for(let i=0; i<sortedErrorsWithRoom.length; i++) {
                        const fi = final.findIndex(f => f.errorDescription === sortedErrorsWithRoom[i].errorDescription)
                        if (fi === -1) {
                            final.push({errorDescription: sortedErrorsWithRoom[i].errorDescription, rooms: [sortedErrorsWithRoom[i].room]})
                        } else {
                            if ((final[fi].rooms.findIndex(r => r === sortedErrorsWithRoom[i].room)) === -1) {
                                final[fi].rooms = [...final[fi].rooms, sortedErrorsWithRoom[i].room]
                            }
                        }
                    }
                    const warning = <ul style={{listStyle: 'none'}}>
                        {final.map(f=>{
                            return (
                                <li>
                                    Error: {f.errorDescription} <br/> 
                                    Rooms: {f.rooms.join(", ")} <br/><br/>
                                </li>
                            )
                        })}
                    </ul>
                    setWarning(warning)
                }
            }
        } catch (e) {
            if (e && e.message) {
                setErrMsg(`Enable failed - ${e.message}`)
            } else if (e) {
                setErrMsg(`Enable failed -  ${e}`)
            } else {
                setErrMsg('Enable failed')
            }
        }
        clearForm()
        setSubmitting(false)
    }
    const handleDisable = async (skillId='', ids=[]) => {
        clearFlags()
        if (!skillId || !ids.length) {
            setErrMsg('Form incomplete')
            return
        }
        setSubmitting(true)
        try {
            let a4hRoomIds: string[] = []
            if (props.managePropertiesSkills) {
                a4hRoomIds = await getA4hRoomIdsFromFacilityIds(ids)
            } else {
                a4hRoomIds = ids
            }
            const disableRes = await Promise.map(
                a4hRoomIds,
                async (a4hRoomId) => {
                    return await Promise.delay(1000).then(async () => {
                        const res = await disableSkill(skillId, a4hRoomId)
                        return {
                            a4hRoomId,
                            res,
                        }
                    })
                },
                {
                    concurrency: 5,
                },
            )

            if (disableRes && disableRes.length) {
              disableRes.map((el) => {
                 if(el.res === ''){
                     setSuccessMsg("Skill disabled in all apartments Successfully")
                 }

                    if (el.res.message) {
                        const sortedErrorsWithRoom = disableRes.map(({ res, a4hRoomId }) => {
                            return {
                           errorDescription:res.message,
                            room:getResRoomName(a4hRoomId)
                        }
                    }).sort((a, b) => {
                        if(a.errorDescription < b.errorDescription) return -1
                        if(a.errorDescription > b.errorDescription) return 1
                        return 0
                    })
                        const final :{errorDescription: string, rooms: string[]}[] = []
                        for(let i = 0; i < sortedErrorsWithRoom.length; i++){
                        const fi = final.findIndex(f => f.errorDescription === sortedErrorsWithRoom[i].errorDescription)
    
                        if(fi === -1) {
                            final.push({errorDescription:sortedErrorsWithRoom[i].errorDescription, rooms:[sortedErrorsWithRoom[i].room]})
                        } else {
                                if((final[fi].rooms.findIndex(r => r === sortedErrorsWithRoom[i].room)) === -1){
                                final[fi].rooms = [...final[fi].rooms, sortedErrorsWithRoom[i].room]
                            } 
                        }
                    }
                    const warning = <ul style={{listStyle: 'none'}}> {final.map(f => <li>
                            Error: {f.errorDescription} <br/>
                            Rooms : {f.rooms.map(r => r).join(", ")} <br/> <br/>
                        </li>)}
                    </ul>
                    setWarning(warning)
                 }
             })
            } 
        } catch (e) {
            setWarning(`Error. ${e.message || e}`)
        }
        clearForm()
        setSubmitting(false)
    }

    useEffect(()=>{
        fetchAndSetDropdownOptions()
    }, [])

    const handleDropdownChange = (value) => {
        if (value.includes('all')) {
            if (props.managePropertiesSkills)
                setSelectedOptions(facilities.map(f => f.id))
            else
                setSelectedOptions(rooms.map(r => r.RoomId))
        }
        else 
            setSelectedOptions(value)
    }
    
    return <>
        <Dimmer active={fetching || submitting} inverted>
            <Loader active={fetching || submitting} />
        </Dimmer>
        <Form widths='equal'>
            <Form.Input 
                label='Skill id' 
                placeholder='amzn1.ask.skill.....' 
                required 
                value={skillId} 
                onChange={(e, {value}) => setSkillId(value)}
            />
            <Form.Field required>
                <label>{props.managePropertiesSkills ? 'Properties' : 'Resident rooms'}</label>
                <Dropdown
                    placeholder={props.managePropertiesSkills ? 'Select properties' : 'Select rooms'}
                    closeOnEscape
                    value={selectedOptions}
                    multiple
                    clearable
                    search
                    scrolling
                    selection
                    options={sortByKey(options)}
                    onChange={(e, {value}) => handleDropdownChange(value)}
                />
            </Form.Field>

            <Form.Group>
                <Form.Button basic color='blue' floated='left' disabled={submitting} onClick={()=>handleEnable(skillId, selectedOptions)}>
                    Enable skill
                </Form.Button>
                <Form.Button basic color='red' floated='right' disabled={submitting} onClick={()=>handleDisable(skillId, selectedOptions)}>
                    Disable skill
                </Form.Button>
            </Form.Group>
        </Form>
        {errMsg && <Message negative>{errMsg}</Message>}
        {!errMsg && warning && <Message warning>{warning}</Message>}
        {!errMsg && !warning && successMsg && <Message positive>{successMsg}</Message>}
    </>
}

export default ManageSkills
