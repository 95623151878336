import React, { useEffect, useState } from 'react'
import { Checkbox, CheckboxProps, Dimmer, Form, Grid, Icon, Item, List, Loader, Popup } from 'semantic-ui-react'
import './style.less'
import { Gadget, Unit } from '../../types'
import SidebarIcon from '../SidebarIcon'
import EditableImage from '../EditableImage'
import { updateRegistrant } from '../../services/Registrants'
import { toast } from 'react-toastify'

interface Props {
    id: string
    name: string
    details: string
    avatarUrl?: string
    onClick: () => any
    roomName?: string
    a4hRoomId?: string
    source?: 'registrants' | "home" | "requests" | "staff" | undefined
    onShowAlexaClick?: (id: string) => void
    isItemSelected?: boolean
    annoucementIconClickHandler?: (id: string | undefined) => void
    notificationIconClickHandler?: (id: string | undefined) => void
    requestIconClickHandler?: (id: string) => void
    residentPremise?: boolean
    onPremiseChangeHandler?: (onPremise: boolean) => void
    onSocialOptOutChangeHandler?: (socialOptOut: boolean) => void;
    isFullScreen?:boolean
    disabled?: boolean
    secondaryResident?: boolean
    residentSocialOptOut?: boolean;
    isExternalResident?: boolean
    isAlisResident?: boolean
    externalIntegrationSource?: string
    CommunityLiaison?: boolean
    isWebRTC?: boolean
    IsActive?: Number
}

const UserItem: React.FC<Props> = props => {
    const [hideButton, setHideButton] = useState<boolean>(false);
    const [smartGadgets, setSmartGadgets] = useState<Gadget[] | []>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const avatarUrl = props.avatarUrl || `${process.env.PUBLIC_URL}/avatar_placeholder.svg`;
    const [residentImageUrl, setResidentImageUrl] = useState("")
    const [dropdownLoading, setDropdownLoading] = useState(false)
    const [isInPremise, setIsInPremise] = useState((props.residentPremise === undefined || props.residentPremise === true) ? true : false);
    const [socialOptOut, setSocialOptOut] = useState((props.residentSocialOptOut === undefined || props.residentSocialOptOut === false) ? false : true);

    const placeholderUrl = `${process.env.PUBLIC_URL}/avatar_placeholder.svg`

    useEffect(() => {
        if (residentImageUrl) { // resident image has been update
            (async () => {
                setIsLoading(true)
                await updateRegistrant(props.id, { Image: residentImageUrl, _id: props.id });
                setIsLoading(false)
            })()
        }
    }, [residentImageUrl])

    async function handleOnPremiseChange(_, data: CheckboxProps) {
        if (props.disabled) {
            toast.warn("Cannot toggle for inactive resident", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            return;
        }
        try {
            setDropdownLoading(true);
            if (typeof data.checked === "boolean") {
                await updateRegistrant(props.id, { onPremise: data.checked, _id: props.id });
            }
            setIsInPremise(data.checked || false);
            if (props.onPremiseChangeHandler) props.onPremiseChangeHandler(data.checked || false)
        } catch (error) {
            console.error({ handleOnPremiseChange: error });
            toast.warn(error instanceof Error ? error.message : "Failed to update data", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            setDropdownLoading(false);
        }
    }

    async function handleSocialOptOutChange(_, data: CheckboxProps) {
        if (props.disabled) {
            toast.warn("Cannot toggle for inactive resident", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            return;
        }
        try {
            setDropdownLoading(true);
            if (typeof data.checked === "boolean") {
                await updateRegistrant(props.id, { Private: data.checked, _id: props.id });
            }
            setSocialOptOut(data.checked || false);
            if (props.onSocialOptOutChangeHandler) props.onSocialOptOutChangeHandler(data.checked || false);
        } catch (error) {
            console.error({ handleSocialOptOutChange: error });
            toast.warn(error instanceof Error ? error.message : "Failed to update data", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            setDropdownLoading(false);
        }
    }

    if (props.isFullScreen) {
        return <>
            {props.source === 'registrants' || props.source === "home" || props.source === "requests" ? (
                <Grid columns={2} centered>
                    <Grid.Column>
                        <Form.Field style={{ display: 'flex', alignItems: 'center' }}>
                            <label style={{ fontSize: '0.8em', color: (!props.disabled && isInPremise) ? '#37b24d' : '#fa5252', fontWeight: 'bold', marginRight: '0.5em' }}>
                                {`${(!props.disabled && isInPremise) ? 'ON' : 'OFF'} Premise`}
                            </label>
                            <Checkbox
                                toggle
                                checked={(!props.disabled && isInPremise)}
                                onChange={handleOnPremiseChange}
                                disabled={dropdownLoading} />
                        </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                        <Form.Field style={{ display: 'flex', alignItems: 'center' }}>
                            <label style={{ fontSize: '0.8em', color: (!props.disabled && socialOptOut) ? '#37b24d' : '#fa5252', fontWeight: 'bold', marginRight: '0.5em', width: '80px' }}>
                                Social Opt Out
                            </label>
                            <Checkbox
                                toggle
                                checked={(!props.disabled && socialOptOut)}
                                onChange={handleSocialOptOutChange}
                                disabled={dropdownLoading} />
                        </Form.Field>
                    </Grid.Column>
                </Grid>
            ) : (
                <></>
            )}
        </>;
    }
    return (
        <Item
            className="UserItem very specific userclass"
            style={{
                background: props.isItemSelected ? '#183466' : '#EDEDED',
                position: props.isItemSelected ? 'sticky' : "static", top: 0, zIndex: 10,
                borderRadius: '10px', paddingBottom: '0em', paddingTop: '0em', marginBottom: '0.5em',
                ...(props.source === "requests" && {display: 'flex', justifyContent: 'space-evenly'} || {})
            }}
        >
            {!props.isWebRTC &&
                <>
                    {
                        props.source === 'registrants' || props.source === "home" || props.source === "requests" ? (
                            <Item.Image
                                avatar
                                className="profile-pic"
                                style={{ paddingTop: '10px', width: '50px', height: '50px', margin: props.source === "home" ? "0px 10px" : "" }}
                            >
                                <Dimmer active={isLoading} inverted>
                                    <Loader active={isLoading} />
                                </Dimmer>
                                <EditableImage
                                    isExternalResident={props.isExternalResident}
                                    isAlisResident={props.isAlisResident}
                                    externalIntegrationSource={props.externalIntegrationSource}
                                    url={residentImageUrl || avatarUrl}
                                    placeholder={residentImageUrl || avatarUrl}
                                    onSelected={(newUrl) => {
                                        if (newUrl) setResidentImageUrl(newUrl);
                                    }}
                                    size={"tiny"}
                                />
                            </Item.Image>
                        ) : (
                            <Item.Image avatar size="tiny" className="profile-pic" src={residentImageUrl || avatarUrl} style={{ paddingTop: '8px', paddingBottom: '8px' }} />
                        )
                    }
                </>
            }

            <Item.Content verticalAlign="middle" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                <Grid columns={props.source === 'registrants' ? 2 : 1}>
                    <Grid.Column fluid={props.source === 'requests' ? true : false} width={props.source === 'registrants' ? 10 : 16}>
                        <Item.Header
                            as="h3"
                            onClick={props.onClick}
                            style={{
                                color: props.isItemSelected ? 'white' : 'black',
                                cursor: 'pointer',
                                wordWrap: 'break-word',
                                fontSize: '1em',
                                margin: 0,
                                padding: 0,
                            }}
                        >
                            {props.CommunityLiaison ? (
                                <>
                                    <div className='center-self'>
                                        {props.name}
                                        <Icon name="star" size='small' color="yellow" style={{ marginLeft: '6px' }} />
                                    </div>
                                </>
                            ) : (
                                props.name
                            )}
                        </Item.Header>
                        {
                            props.secondaryResident &&
                            <div style={{ color: props.isItemSelected ? 'white' : 'black', marginTop: '5px', marginBottom: '-5xpx' }}>Secondary resident </div>
                        }
                        <Item.Extra style={{ color: props.isItemSelected ? 'white' : 'black', fontSize: '1em' }}>
                            {props.roomName && `Apartment ${props.roomName}`}
                        </Item.Extra>
                        {(props.disabled || (props.source === "staff" && props.IsActive === 0)) && (
                            <Item.Extra style={{ color: 'red', fontSize: '1em' }}>{`Inactive Resident`}</Item.Extra>
                        )}
                        <Item.Meta style={{ color: props.isItemSelected ? 'white' : 'black', fontSize: '1em' }}>
                            {props.details}
                        </Item.Meta>
                        {props.source === 'registrants' || props.source === 'home' || props.source === 'requests' ? (
                            <>
                                {props.isWebRTC ?
                                    <div
                                        style={{
                                            color: (!props.disabled && isInPremise) ? '#37b24d' : '#fa5252',
                                            display: "block"
                                        }}
                                    >
                                        {`${(!props.disabled && isInPremise) ? 'ON' : 'OFF'} Premise`}
                                    </div> :
                                    <Grid columns={2}>
                                        <Grid.Column style={{ padding: '1em 0', textAlign: 'center' }}>
                                            <Form.Field>
                                                <div
                                                    style={{
                                                        fontSize: '0.7em',
                                                        color: (!props.disabled && isInPremise) ? '#37b24d' : '#fa5252',
                                                        fontWeight: 'bold',
                                                        display: "block"
                                                    }}
                                                >
                                                    {`${(!props.disabled && isInPremise) ? 'ON' : 'OFF'} Premise`}
                                                </div>
                                                <Checkbox
                                                    toggle
                                                    checked={(!props.disabled && isInPremise)}
                                                    onChange={handleOnPremiseChange}
                                                    disabled={dropdownLoading} />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column style={{ padding: '1em 0', textAlign: 'center' }}>
                                            <Form.Field>
                                                <label style={{ fontSize: '0.7em', color: (!props.disabled && socialOptOut) ? '#37b24d' : '#fa5252', fontWeight: 'bold', display: "block" }}>Social Opt Out</label>
                                                <Checkbox
                                                    toggle
                                                    checked={(!props.disabled && socialOptOut)}
                                                    onChange={handleSocialOptOutChange}
                                                    disabled={dropdownLoading} />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                }
                            </>
                        ) : (
                            <></>
                        )}
                    </Grid.Column>
                    {props.source === 'registrants' ? (
                        <Grid.Column width={6}>
                            <List horizontal={false} relaxed>
                                <Popup
                                    content="Announcement"
                                    trigger={
                                        <List.Item
                                            onClick={() => {
                                                if (props.annoucementIconClickHandler)
                                                    props.annoucementIconClickHandler(props.a4hRoomId);
                                            }}
                                            style={{ cursor: 'pointer', marginLeft: '4px' }}
                                        >
                                            <SidebarIcon
                                                name="announcements"
                                                white={props.isItemSelected ? true : false}
                                                smallSize
                                            />
                                        </List.Item>
                                    }
                                    pinned
                                    position="right center"
                                    size="tiny"
                                />

                                <Popup
                                    content="Notifications"
                                    trigger={
                                        <List.Item
                                            onClick={() => {
                                                if (props.notificationIconClickHandler)
                                                    props.notificationIconClickHandler(props.a4hRoomId);
                                            }}
                                            style={{ cursor: 'pointer', marginLeft: '4px' }}
                                        >
                                            <SidebarIcon
                                                name="notifications"
                                                white={props.isItemSelected ? true : false}
                                                smallSize
                                            />
                                        </List.Item>
                                    }
                                    pinned
                                    position="right center"
                                    size="tiny"
                                />

                                <Popup
                                    content="Requests"
                                    trigger={
                                        <List.Item
                                            onClick={() => {
                                                if (props.requestIconClickHandler)
                                                    props.requestIconClickHandler(props.id);
                                            }}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <SidebarIcon
                                                name="requests"
                                                white={props.isItemSelected ? true : false}
                                                smallSize
                                            />
                                        </List.Item>
                                    }
                                    pinned
                                    position="right center"
                                    size="tiny"
                                />
                            </List>
                        </Grid.Column>
                    ) : (
                        <></>
                    )}
                </Grid>
            </Item.Content>
        </Item>
    );
}

export default UserItem
